/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-else-return */
import { Box, SimpleGrid, Text, Flex, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SiteViewCard from './SiteViewCard';
import {
  CUSTOM_TOAST_DURATION,
  HEADER_COLORS,
  TOAST_SETTINGS,
  USER_TYPE
} from '../../../constants';
import {
  getSiteViewAveHourseData,
  getSiteViewLeaversData,
  getSiteViewPerformanceData,
  getSiteViewPoolUtilisationData,
  getSiteViewShiftFulfilmentData,
  getSiteViewShiftUtilisationData,
  getSiteViewSpendNHoursData
} from '../../../redux/action/performanceGraph.action';
import Loader from '../../../components/Loader';

const SiteViewPerformance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [siteViewData, setSiteViewData] = useState([]);
  const { userData, user_details } = useSelector((state) => state.authentication);
  const { selected_client_id, selected_performance_region_id } = useSelector(
    (state) => state.agency
  );
  const { startDate, endDate } = useSelector((state) => state.weekRange);
  const toast = useToast();

  const getClientId = () => {
    if (!isLoading) {
      switch (user_details.user_type_id) {
        case USER_TYPE.CLIENT_ADMIN:
          return userData.client_id;
        case USER_TYPE.AGENCY_ADMIN:
        case USER_TYPE.AGENCY_REGION_ADMIN:
        case USER_TYPE.AGENCY_SITE_ADMIN:
          return selected_client_id || userData.client_id;
        default:
          return selected_client_id || userData.client_id;
      }
    }
    return null;
  };

  const getRegionId = () => {
    if (!isLoading) {
      switch (user_details.user_type_id) {
        case USER_TYPE.SUPER_ADMIN:
        case USER_TYPE.CLIENT_ADMIN:
        case USER_TYPE.AGENCY_ADMIN:
          return selected_performance_region_id;
        case USER_TYPE.AGENCY_REGION_ADMIN:
          return user_details.region_id;
        case USER_TYPE.AGENCY_SITE_ADMIN:
        case USER_TYPE.SITE_ADMIN:
          return '';
        default:
          return selected_performance_region_id || userData.region_id;
      }
    }
    return null;
  };
  const checkAllAPIStatus = (responses) =>
    responses.every((response) => response.ok && response.data?.ok);

  const transformSiteData = (siteMap, responseArry) => {
    responseArry.forEach((res) => {
      res.data.result.forEach((data) => {
        const { s_id = 0, s_name = '', ...otherData } = data;
        const key = JSON.stringify({ s_id, s_name });
        const currentSite = siteMap.get(key);
        siteMap.set(key, { ...currentSite, ...otherData });
      });
    });

    return Array.from(siteMap, ([key, values]) => ({ ...JSON.parse(key), data: { ...values } }));
  };
  const fetchSiteCardData = async () => {
    try {
      setSiteViewData([]);
      setIsLoading(true);
      const apiResponses = await Promise.all([
        getSiteViewShiftFulfilmentData(getClientId(), startDate, endDate, getRegionId()),
        getSiteViewAveHourseData(getClientId(), startDate, endDate, getRegionId()),
        getSiteViewPoolUtilisationData(getClientId(), startDate, endDate, getRegionId()),
        getSiteViewLeaversData(getClientId(), startDate, endDate, getRegionId()),
        getSiteViewSpendNHoursData(getClientId(), startDate, endDate, getRegionId()),
        getSiteViewPerformanceData(getClientId(), startDate, endDate, getRegionId()),
        getSiteViewShiftUtilisationData(getClientId(), startDate, endDate, getRegionId())
      ]);
      if (checkAllAPIStatus(apiResponses)) {
        const mergedData = transformSiteData(new Map(), apiResponses);
        setSiteViewData(mergedData);
      } else {
        throw new Error();
      }
    } catch (error) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.ERROR,
        title: 'Something went wrong try again later'
      });
    }
    setIsLoading(false);
  };
  useEffect(async () => {
    if (getClientId()) {
      fetchSiteCardData();
    }
  }, [startDate, endDate, selected_performance_region_id]);

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Loader />
      </Flex>
    );
  }
  if (siteViewData?.length === 0) {
    return (
      <Flex mt="20" justifyContent="center" alignItems="center">
        <Text textAlign="center" color="gray.500">
          No data available
        </Text>
      </Flex>
    );
  }
  return (
    <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} spacing={4} p={4} mt={12}>
      {siteViewData?.map((item) => (
        <Box
          key={item.s_id}
          border="solid"
          borderColor={HEADER_COLORS.ACTIVITY_COLOR}
          borderRadius="lg"
          p={1}>
          <SiteViewCard key={item.s_id} title={item.s_name} data={item.data} />
        </Box>
      ))}
    </SimpleGrid>
  );
};
export default SiteViewPerformance;
