/* eslint-disable react/function-component-definition */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import moment from 'moment';
import { Box, Button, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import constants, { WEEK_RANGE_ACTIONS } from '../../constants';
import './formStyles.css';

const WeekRangePicker = ({ weekStartDay, selectedMultipleWeeks = false, disabled = false }) => {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState([]);
  const dayIndex = weekStartDay || 0; // Default to Sunday if not provided
  const { startDate, endDate } = useSelector((state) => state.weekRange);
  const { selected_client_id, selected_performance_dashboard_type } = useSelector(
    (state) => state.agency
  );
  const calendarRef = useRef();

  // Helper function to get current week range based on weekStartDay
  const getCurrentWeekRange = () => {
    const today = moment().endOf('day');
    const currentDayIndex = today.day();
    const diff = currentDayIndex - dayIndex;

    // Calculate the start of the current week based on weekStartDay
    const weekStart = today
      .clone()
      .subtract(diff >= 0 ? diff : diff + 7, 'days')
      .startOf('day');

    // If today is before the calculated week end, adjust to previous week
    const weekEnd = weekStart.clone().add(6, 'days').endOf('day');

    // For non-Shift Report views, if today is before week end, adjust to previous week
    if (selected_performance_dashboard_type !== 'Shift Report' && today.isBefore(weekEnd)) {
      weekStart.subtract(7, 'days');
      weekEnd.subtract(7, 'days');
    }

    return [
      new DateObject(weekStart.format('YYYY-MM-DD')),
      new DateObject(weekEnd.format('YYYY-MM-DD'))
    ];
  };

  // Effect to handle weekStartDay changes and client changes
  useEffect(() => {
    const initialRange = getCurrentWeekRange();
    setDateRange(initialRange);

    // Update Redux with new range
    const start = moment(initialRange[0].format('YYYY-MM-DD')).startOf('day');
    const end = moment(initialRange[1].format('YYYY-MM-DD')).endOf('day');

    dispatch({
      type: WEEK_RANGE_ACTIONS.SET_WEEK_RANGE,
      payload: {
        startDate: start.format('YYYY-MM-DD'),
        endDate: end.format('YYYY-MM-DD')
      }
    });
  }, [selected_client_id, weekStartDay, dayIndex, selected_performance_dashboard_type]); // Add weekStartDay and dayIndex as dependencies

  useEffect(() => {
    if (dateRange.length && !selectedMultipleWeeks) {
      const start = moment(dateRange[0].format('YYYY-MM-DD')).startOf('day');
      const end = moment(dateRange[1].format('YYYY-MM-DD')).endOf('day');

      // Only update Redux if the dates are different
      if (start.format('YYYY-MM-DD') !== startDate || end.format('YYYY-MM-DD') !== endDate) {
        dispatch({
          type: WEEK_RANGE_ACTIONS.SET_WEEK_RANGE,
          payload: {
            startDate: start.format('YYYY-MM-DD'),
            endDate: end.format('YYYY-MM-DD')
          }
        });
      }
    }
  }, [dateRange]); // Only run when dateRange changes

  const handleDateChange = (event) => {
    if (event.length === 1) {
      const selectedDate = moment(event[0].toDate());
      const today = moment().endOf('day');

      // Only restrict future dates for non-Shift Report views
      if (selected_performance_dashboard_type !== 'Shift Report' && selectedDate.isAfter(today)) {
        return;
      }

      const client_index = dayIndex;
      const current_index = event[0].weekDay.index;

      let weekStart;
      let weekEnd;

      if (client_index > current_index) {
        const forward_index = client_index - current_index - 1;
        const index = 7 - (client_index - current_index);

        weekStart = moment(event[0].toDate()).subtract(index, 'days').startOf('day');
        weekEnd = moment(event[0].toDate()).add(forward_index, 'days').endOf('day');
      } else {
        const index = current_index - client_index;
        const forward_index = 7 - index - 1;
        weekStart = moment(event[0].toDate()).subtract(index, 'days').startOf('day');
        weekEnd = moment(event[0].toDate()).add(forward_index, 'days').endOf('day');
      }

      // Only adjust to previous week for non-Shift Report views
      if (selected_performance_dashboard_type !== 'Shift Report' && weekEnd.isAfter(today)) {
        weekStart.subtract(7, 'days');
        weekEnd.subtract(7, 'days');
      }
      const dateArray = [];

      if (dateRange.length > 0 && selectedMultipleWeeks) {
        if (moment(dateRange[0].toDate()).diff(weekStart) > 0) {
          dateArray.push(new DateObject(weekStart.format('YYYY-MM-DD')));
        } else dateArray.push(dateRange[0]);

        if (moment(dateRange[1].toDate()).diff(weekEnd) < 0) {
          dateArray.push(new DateObject(weekEnd.format('YYYY-MM-DD')));
        } else dateArray.push(dateRange[1]);
      } else {
        dateArray.push(
          ...[
            new DateObject(weekStart.format('YYYY-MM-DD')),
            new DateObject(weekEnd.format('YYYY-MM-DD'))
          ]
        );
      }

      setDateRange(dateArray);
    }
  };

  const handleSubmit = () => {
    if (dateRange.length) {
      const start = moment(dateRange[0].format('YYYY-MM-DD')).startOf('day');
      const end = moment(dateRange[1].format('YYYY-MM-DD')).endOf('day');
      // Only update Redux if the dates are different
      if (start.format('YYYY-MM-DD') !== startDate || end.format('YYYY-MM-DD') !== endDate) {
        dispatch({
          type: WEEK_RANGE_ACTIONS.SET_WEEK_RANGE,
          payload: {
            startDate: start.format('YYYY-MM-DD'),
            endDate: end.format('YYYY-MM-DD')
          }
        });
      }
    } else if (!dateRange.length) {
      const initialRange = getCurrentWeekRange();
      setDateRange(initialRange);
      const start = moment(initialRange[0].format('YYYY-MM-DD')).startOf('day');
      const end = moment(initialRange[1].format('YYYY-MM-DD')).endOf('day');
      // Only update Redux if the dates are different
      if (start.format('YYYY-MM-DD') !== startDate || end.format('YYYY-MM-DD') !== endDate) {
        dispatch({
          type: WEEK_RANGE_ACTIONS.SET_WEEK_RANGE,
          payload: {
            startDate: start.format('YYYY-MM-DD'),
            endDate: end.format('YYYY-MM-DD')
          }
        });
      }
    }
    calendarRef.current.closeCalendar();
  };

  return (
    <Flex alignItems="flex-end">
      <Box width="14rem" mr={4}>
        <p className="input-label">Select Week Range</p>
        <DatePicker
          ref={calendarRef}
          format="DD/MM/YYYY"
          inputClass={disabled ? 'date-picker-grey-disabled' : 'date-picker'}
          range
          placeholder="Select Week Range"
          maxDate={selected_performance_dashboard_type === 'Shift Report' ? null : new Date()}
          editable={false}
          onChange={handleDateChange}
          value={dateRange}
          className="booking-datepicker"
          weekStartDayIndex={dayIndex}
          disabled={disabled}>
          {selectedMultipleWeeks && (
            <>
              <Button
                type="button"
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => {
                  setDateRange([]);
                }}
                size="xs"
                mb={4}>
                {constants.DASHBOARD_VIEW.CANCEL_BUTTON_TEXT}
              </Button>
              <Button
                type="button"
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => handleSubmit()}
                size="xs"
                mb={4}
                ms={2}>
                {constants.DASHBOARD_VIEW.OK_BUTTON_TEXT}
              </Button>
            </>
          )}
        </DatePicker>
      </Box>
    </Flex>
  );
};

export default WeekRangePicker;
