/* eslint-disable radix */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Spinner,
  Text,
  Tooltip,
  useToast
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import '../../components/Forms/formStyles.css';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import constants, {
  PATH,
  TOAST_SETTINGS,
  USER_TYPE,
  PAGE_TITLE,
  DATE_TO_SHOW,
  CUSTOM_TOAST_DURATION,
  DaySelectionList,
  CUSTOM_TOAST_DURATION_NULL,
  FILE_FORMAT
} from '../../constants';
import Card from '../../components/Card';
import ServerSideDatatable from '../../components/ServerSideDatatable';
import Loader from '../../components/Loader';
import StyledAttendanceContainer, {
  StyledAttendanceHeaderContainer,
  StyledDownloadSample,
  StyledUploadFileButton,
  StyledUploadLabel,
  StyledWeekText
} from './Attendance.styled';
import {
  deletePayrollData,
  deleteTapDataSheet,
  downloadPayrollData,
  downloadPayrollDetailsData,
  downloadPayrollSample,
  downloadTAndASample,
  downloadTapClockReport,
  downloadTapSample,
  getPayrollData,
  uploadAttendanceData,
  uploadPayrollData,
  uploadTapData
} from '../../redux/action/attendance.action';
import ModalComponent from '../../components/Modal';
import { getModulePermissions } from '../../utils/hooks';
import InputComponent from '../../components/Input';
import DownloadIcon from '../../icons/DownloadIcon';
import AgencyDropdown from '../../components/Forms/AgencyDropdown';
import { addEuroSymbol, exportToExcel, filterErrorData } from '../../utils/helper';
import { getClientById, getYearlyRule } from '../../redux/action/client.action';
import { getSites } from '../../redux/action/site.action';
import DeleteIcon from '../../assets/images/delete-icon.png';
import AlertDialogComponent from '../../components/AlertModalComponent';
import PerformanceModal from '../../components/PerformanceModal';
import { enableFTPLookup } from '../../redux/action/worker.action';

const INITIAL_COLUMNS = [
  {
    label: 'Date',
    field: 'start_date',
    sort: true,
    customRow: (cell) => {
      return `${DATE_TO_SHOW(cell)}`;
    }
  },
  { label: 'Agency', field: 'agency_name', sort: true },
  { label: 'Total Hours', field: 'total_hours', sort: true },
  {
    label: 'Worked Hours Charge',
    field: 'worked_hours_charge',
    sort: true,
    customRow: (cell) => {
      if (cell !== null && cell !== undefined) {
        return `${addEuroSymbol(cell)}`;
      }
      return `-`;
    }
  },
  {
    label: 'Worked Employment Cost',
    field: 'actual_employment_costs',
    sort: true,
    customRow: (cell) => {
      return `${addEuroSymbol(cell)}`;
    }
  },
  {
    label: 'Other Assignment Pay',
    field: 'other_assignment_pay_value',
    sort: true,
    customRow: (cell) => {
      if (cell !== null && cell !== undefined) {
        return `${addEuroSymbol(cell)}`;
      }
      return `-`;
    }
  },
  {
    label: 'Total Agency Margin',
    field: 'total_agency_margin',
    sort: true,
    customRow: (cell) => {
      return `${addEuroSymbol(cell)}`;
    }
  },
  {
    label: 'Actual Margin Per Hr',
    field: 'actual_margin',
    sort: true,
    customRow: (cell) => {
      return `${addEuroSymbol(cell)}`;
    }
  },
  {
    label: 'Expected Agency Margin',
    field: 'rate_card_margin',
    sort: true,
    customRow: (cell) => {
      return `${addEuroSymbol(cell)}`;
    }
  },
  {
    label: 'Identified Grey Margin',
    field: 'clearvue_savings',
    sort: true,
    customRow: (cell) => {
      return `${addEuroSymbol(cell)}`;
    }
  },
  {
    label: 'WTR Costs',
    field: 'total_wtr_costs',
    sort: true,
    customRow: (cell) => {
      if (cell !== null && cell !== undefined) {
        return `${addEuroSymbol(cell)}`;
      }
      return `-`;
    }
  },
  {
    label: 'Training Credits',
    field: 'training_credits',
    sort: true,
    customRow: (cell) => {
      if (cell !== null && cell !== undefined) {
        return `${addEuroSymbol(cell)}`;
      }
      return `-`;
    }
  },
  {
    label: 'Credit Value',
    field: 'credit_value',
    sort: true,
    customRow: (cell) => {
      if (cell !== null && cell !== undefined) {
        return `${addEuroSymbol(cell)}`;
      }
      return `-`;
    }
  },
  {
    label: 'Total Cost',
    field: 'total_cost',
    sort: true,
    customRow: (cell) => {
      if (cell !== null && cell !== undefined) {
        return `${addEuroSymbol(cell)}`;
      }
      return `-`;
    }
  },
  {
    label: 'WTR Accrual',
    field: 'accrual_value',
    sort: true,
    customRow: (cell) => {
      if (cell !== null && cell !== undefined) {
        return `${addEuroSymbol(cell)}`;
      }
      return `-`;
    }
  }
];

const Attendance = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.ATTENDANCE;
  }, []);
  const toast = useToast();
  const { selected_client_id, total_assignment_pay } = useSelector((state) => state.agency);
  const { user_details } = useSelector((state) => state.authentication);
  const { selected_client_name } = useSelector((state) => state.agency);
  const { selected_site_id } = useSelector((state) => state.agency);
  const { selected_site_name } = useSelector((state) => state.agency);
  const { selected_start_date } = useSelector((state) => state.agency);
  const { selected_end_date, is_restricted, weekday_start } = useSelector((state) => state.agency);
  const { user_details: userDetails } = useSelector((state) => state.authentication);
  const permission = getModulePermissions(PATH.TIME_AND_ATTENDANCE.CODE);
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [openPayrollDeleteModal, setopenPayrollDeleteModal] = useState(false);
  const [openWorkerPerformanceModal, setOpenWorkerPerformanceModal] = useState(false);
  const [tnaFtp, setTnaFtp] = useState();
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([
    {
      label: 'Week',
      field: 'week',
      sort: true,
      customRow: (cell, row) => {
        if (row.highlighted_payroll === 1) {
          return (
            <Tooltip
              bg="white"
              color="red"
              placement="right-end"
              label="IN THESE CALCULATIONS EMPLOYER COSTS FOR HOLIDAY PAY HAS BEEN REMOVED FROM THE RATE CARD PROVIDING ADDITIONAL SAVINGS">
              <StyledWeekText onClick={() => onWeekClickHandler(row)}>
                {cell}
                <span style={{ color: 'red' }}>*</span>
              </StyledWeekText>
            </Tooltip>
          );
        }
        return <StyledWeekText onClick={() => onWeekClickHandler(row)}>{cell}</StyledWeekText>;
      }
    },
    ...INITIAL_COLUMNS
  ]);
  const [reload, setReload] = useState(false);
  const [reloadSites, setReloadSites] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [currentWeek, setCurrentWeek] = useState('');
  const [selectedWorkerFile, setSelectedWorkerFile] = useState(null);
  const [selectedReportFile, setSelectedReportFile] = useState(null);
  const [selectedPayrollData, setSelectedPayrollData] = useState(null);
  const [agency, setAgency] = useState({});
  const [uploadedWorkerFileName, setUploadedWorkerFileName] = useState(null);
  const [uploadedReportFileName, setUploadedReportFileName] = useState(null);
  const [clientName, setClientName] = useState('');
  // const [siteName, setSiteName] = useState('');
  const [sitesList, setSitesList] = useState([]);
  const [confirmBtnClicked, setconfirmBtnClicked] = useState(false);
  const [agencyId, setagencyId] = useState('');
  const [siteId, setsiteId] = useState('');
  const [startDateData, setstartDate] = useState('');
  const [weekData, setWeek] = useState('');
  const [dateRange, setDateRange] = useState();
  const [selectweekDay, setselectweekDay] = useState();
  const [tapBtnClicked, setTapBtnClicked] = useState(false);
  const dayIndex = DaySelectionList.indexOf(weekday_start);
  const [tapValue, settapValue] = useState(0);
  const [ftpLoader, setFtpLoader] = useState(false);
  const [yearlyRules, setYearlyRules] = useState([]);
  useEffect(() => {
    if (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN) {
      setColumns([
        ...columns,
        {
          label: 'Action',
          field: 'id',
          sort: false,
          customRow: (_cell, row) => {
            return (
              <div style={{ display: 'flex' }}>
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={() => {
                    setopenPayrollDeleteModal(true);
                    setagencyId(row.agency_id);
                    setstartDate(row.start_date);
                    setsiteId(row.site_id);
                    setWeek(row.week);
                  }}>
                  <Image src={DeleteIcon} height={6} />
                </Button>
              </div>
            );
          }
        }
      ]);
    }
  }, []);
  useEffect(() => {
    if (
      userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
      userDetails.user_type_id === USER_TYPE.REGION_ADMIN ||
      userDetails.user_type_id === USER_TYPE.SITE_ADMIN
    ) {
      columns.splice(3, 0, {
        label: 'Site',
        field: 'site_name',
        sort: true
      });
    }
  }, [columns, userDetails]);

  const getClientByIdFunction = async () => {
    const result = await getClientById(userDetails.client_id);
    if (result && result.ok) {
      setTnaFtp(result.data.client_details.tna_ftp);
      setClientName(result.data.client_details.name);
      const weekDayIndex = DaySelectionList.indexOf(result.data.client_details.weekday_start);
      setselectweekDay(weekDayIndex);
      settapValue(result.data.client_details.total_assignment_pay);
    }
  };

  const getClientYearlyRule = async (clientId) => {
    const result = await getYearlyRule(clientId);
    if (result.ok && result.data.ok) {
      setYearlyRules(result.data.rules);
    }
  };

  if (
    userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
    userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
    userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
    userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
    userDetails.user_type_id === USER_TYPE.REGION_ADMIN
  ) {
    useEffect(() => {
      getClientByIdFunction();
    }, []);
  }
  useEffect(() => {
    if (selected_client_id || userDetails.client_id) {
      getClientYearlyRule(selected_client_id || userDetails.client_id);
    }
  }, [selected_client_id, userDetails.client_id]);

  const getSitesFunction = async () => {
    const sites = await getSites(user_details.client_id);
    if (sites && sites.ok) {
      setSitesList(sites.data.sites);
    }
  };

  if (userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) {
    useEffect(() => {
      getSitesFunction();
    }, []);
  }

  useEffect(() => {
    setSitesList(
      sitesList.filter((value) => parseInt(value.id) === parseInt(user_details.site_id))
    );
  }, [sitesList.length]);

  const retrievePayrollData = async (...params) => {
    const result = await getPayrollData(
      selected_client_id || userDetails.client_id,
      userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? userDetails.agency_id
        : userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN && agency.value
        ? agency.value
        : null,
      userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN
        ? ''
        : userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
        ? userDetails.site_id
        : selected_site_id,
      '',
      selected_start_date,
      selected_end_date,
      ...params
    );
    if (result.ok) {
      return result;
    }
    return {
      data: []
    };
  };

  const onWeekClickHandler = (rowData) => {
    setSelectedPayrollData({ ...rowData });
    setIsEdit(true);
    setIsUploadModalOpen(true);
  };

  const onCloseUploadModal = () => {
    if (!confirmBtnClicked) {
      setIsUploadModalOpen(false);
      setIsEdit(false);
      setSelectedPayrollData(null);
      setSelectedWorkerFile(null);
      setSelectedReportFile(null);
      setUploadedWorkerFileName(null);
      setUploadedReportFileName(null);
    }
    if (tapBtnClicked) {
      setTapBtnClicked(false);
    }
  };
  const getRelativeWeekNumber = (yearStartDate, selectedEndDate) => {
    const startOfYear = moment(yearStartDate, 'YYYY-MM-DD');
    const selectedEnd = moment(selectedEndDate, 'DD/MM/YYYY');

    return (selectedEnd.diff(startOfYear, 'days') + 1) / 7;
  };
  const findWeekOfYear = (startDate, endDate) => {
    const { year } = startDate;
    const yearlyRule = yearlyRules.find(
      (r) =>
        moment(startDate.toString(), 'DD/MM/YYYY').isBetween(r.start_date, r.end_date) ||
        moment(endDate.toString(), 'DD/MM/YYYY').isBetween(r.start_date, r.end_date)
    );
    if (yearlyRule) {
      return getRelativeWeekNumber(yearlyRule.start_date, endDate.toString());
    }
    const firstJan = moment(`${year}0101`, 'YYYYMMDD');
    const fifthApril = moment(`${year}0405`, 'YYYYMMDD');
    const dayDiff = fifthApril.diff(firstJan, 'days');
    const weekCount = parseInt((dayDiff + (firstJan.day() + 1) + (fifthApril.day() + 1)) / 7);
    // TODO : Remove below line
    return moment(`${dateRange[0].year}-${dateRange[0].month}-${dateRange[0].day}`, 'YYYY-MM-DD')
      .subtract(weekCount, 'weeks')
      .weeks();
  };
  const onOpenWorkerPerformanceModal = () => {
    if (dateRange) {
      setCurrentWeek(findWeekOfYear(dateRange[0], dateRange[1]));
      setOpenWorkerPerformanceModal((prev) => !prev);
    }
  };
  const onCloseWorkerPerformance = () => {
    setOpenWorkerPerformanceModal(false);
  };

  const onCloseDeleteModal = () => {
    setopenPayrollDeleteModal(false);
  };
  const confirmDeletePayroll = async () => {
    const payload = {
      client_id: selected_client_id || userDetails.client_id,
      agency_id: agencyId,
      site_id: siteId,
      start_date: startDateData
    };
    const result = await deletePayrollData(payload);
    if (result && result.ok) {
      setopenPayrollDeleteModal(false);
      setReload(!reload);
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const openUploadModal = (tap_value) => {
    if (tap_value === true) {
      setTapBtnClicked(true);
    }
    if (dateRange) {
      setIsUploadModalOpen(true);
      setCurrentWeek(findWeekOfYear(dateRange[0], dateRange[1]));
    }
  };

  const downloadBtnClickHandler = async () => {
    if (isEdit) {
      const result = await downloadPayrollSample();
      if (result.ok) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.setAttribute('href', result.data.resource_url);
        a.setAttribute('download', 'Payroll-Report-Sample');
        a.click();
      }
    } else if (tapBtnClicked === true) {
      const result = await downloadTapSample();
      if (result.ok) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.setAttribute('href', result.data.resource_url);
        a.setAttribute('download', 'Total-Assignment-Pay-Sample');
        a.click();
      }
    } else {
      const result = await downloadTAndASample();
      if (result.ok) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.setAttribute('href', result.data.resource_url);
        a.setAttribute('download', 'Worker-CSV-Sample');
        a.click();
      }
    }
  };

  const downloadTapBtnClickHandler = async () => {
    const startDate = moment(
      `${dateRange[0].year}/${dateRange[0].month}/${dateRange[0].day}`,
      'YYYY-MM-DD'
    );
    const endDate = moment(
      `${dateRange[1].year}/${dateRange[1].month}/${dateRange[1].day}`,
      'YYYY-MM-DD'
    );
    const week = currentWeek;
    const result = await downloadTapClockReport(
      userDetails.client_id || selected_client_id,
      userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
        ? userDetails.site_id
        : selected_site_id,
      userDetails.agency_id,
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD'),
      week
    );
    if (result.ok) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.setAttribute('href', result.data.resource_url);
      a.setAttribute('download', 'Tap-Clock-Report');
      a.click();
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const deleteTapSheet = async () => {
    const startDate = moment(
      `${dateRange[0].year}/${dateRange[0].month}/${dateRange[0].day}`,
      'YYYY-MM-DD'
    );

    const payload = {
      client_id: userDetails.client_id || selected_client_id,
      agency_id: userDetails.agency_id,
      site_id:
        userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
          ? userDetails.site_id
          : selected_site_id,
      start_date: startDate.format('YYYY-MM-DD')
    };

    const result = await deleteTapDataSheet(payload);

    if (result && result.ok) {
      setopenPayrollDeleteModal(false);
      setReload(!reload);
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const handelWorkerFileChange = (event) => {
    setUploadedWorkerFileName(event.target.files[0].name);
    setSelectedWorkerFile(event.target.files[0]);
  };

  const getConfirmedBtn = () => {
    let btnTemplate = null;
    if (
      USER_TYPE.AGENCY_ADMIN === userDetails.user_type_id ||
      userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
      userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
    ) {
      btnTemplate = (
        <Flex alignItems="center">
          {confirmBtnClicked && (
            <>
              <Text mr="10px">We are uploading the file, so please stay tuned</Text>
              <Spinner size="sm" mr="10px" color="main.primary" />
            </>
          )}
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            disabled={confirmBtnClicked}
            onClick={onConfirmBtnClicked}>
            {constants.ATTENDANCE.ATTENDANCE_UPLOAD_BUTTON_TEXT}
          </Button>
        </Flex>
      );
      if (isEdit) {
        if (selectedPayrollData.detail_payroll_report) {
          btnTemplate = null;
        }
      }
    }
    return btnTemplate;
  };

  const onConfirmBtnClicked = () => {
    if (!confirmBtnClicked) {
      confirmBtnClickHandler();
    }
  };

  const confirmBtnClickHandler = async () => {
    if (isEdit) {
      if (!selectedReportFile) {
        toast({
          ...TOAST_SETTINGS.ERROR,
          status: TOAST_SETTINGS.ERROR,
          title: 'Please first select a file.'
        });
        return;
      }
      const result = await uploadPayrollData({
        client_id: selectedPayrollData.client_id,
        site_id: selectedPayrollData.site_id,
        agency_id: selectedPayrollData.agency_id,
        start_date: selectedPayrollData.start_date,
        end_date: selectedPayrollData.end_date,
        week: selectedPayrollData.week,
        payroll_report: selectedReportFile
      });
      if (result && result.ok) {
        setReload(!reload);
        onCloseUploadModal();
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION_NULL.duration,
          status: TOAST_SETTINGS.ERROR,
          title: filterErrorData(result.error)
        });
      }
    } else if (tapBtnClicked === true) {
      if (!selected_site_id) {
        toast({
          ...TOAST_SETTINGS.ERROR,
          status: TOAST_SETTINGS.ERROR,
          title: 'Please first select the site.'
        });
        return;
      }

      if (!selectedWorkerFile) {
        toast({
          ...TOAST_SETTINGS.ERROR,
          status: TOAST_SETTINGS.ERROR,
          title: 'Please first select a file.'
        });
        return;
      }

      const startDate = moment(
        `${dateRange[0].year}/${dateRange[0].month}/${dateRange[0].day}`,
        'YYYY-MM-DD'
      );
      const endDate = moment(
        `${dateRange[1].year}/${dateRange[1].month}/${dateRange[1].day}`,
        'YYYY-MM-DD'
      );

      setconfirmBtnClicked(true);
      const week = currentWeek;
      const result = await uploadTapData({
        client_id: userDetails.client_id || selected_client_id,
        site_id:
          userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
            ? userDetails.site_id
            : selected_site_id,
        agency_id: userDetails.agency_id,
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD'),
        week,
        totalAgencyPay: selectedWorkerFile
      });
      if (result) {
        setconfirmBtnClicked(false);
      }

      if (result && result.ok) {
        setReload(!reload);
        setTapBtnClicked(false);
        onCloseUploadModal();
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION_NULL.duration,
          status: TOAST_SETTINGS.ERROR,
          title: filterErrorData(result.error)
        });
      }
    } else {
      if (!selected_site_id) {
        toast({
          ...TOAST_SETTINGS.ERROR,
          status: TOAST_SETTINGS.ERROR,
          title: 'Please first select the site.'
        });
        return;
      }
      if (!selectedWorkerFile) {
        toast({
          ...TOAST_SETTINGS.ERROR,
          status: TOAST_SETTINGS.ERROR,
          title: 'Please first select a file.'
        });
        return;
      }

      // TODO : Remove below block and un comment above
      const startDate = moment(
        `${dateRange[0].year}/${dateRange[0].month}/${dateRange[0].day}`,
        'YYYY-MM-DD'
      );
      const endDate = moment(
        `${dateRange[1].year}/${dateRange[1].month}/${dateRange[1].day}`,
        'YYYY-MM-DD'
      );

      setconfirmBtnClicked(true);
      const week = currentWeek;
      const result = await uploadAttendanceData({
        client_id: userDetails.client_id || selected_client_id,
        site_id:
          userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
            ? userDetails.site_id
            : selected_site_id,
        agency_id: userDetails.agency_id,
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD'),
        week,
        timeAndAttendance: selectedWorkerFile
      });
      if (result) {
        setconfirmBtnClicked(false);
      }

      if (result && result.ok) {
        setReload(!reload);
        onCloseUploadModal();
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION_NULL.duration,
          status: TOAST_SETTINGS.ERROR,
          title: filterErrorData(result.error)
        });
      }
    }
  };
  const ReportMapFields = {
    employee_id: 'Employee ID',
    total_hours: 'Total Hours',
    total_charge: 'Total Charge',
    actual_employment_costs: 'Worked Employment Cost',
    total_margin: 'Total Margin',
    actual_margin_per_hour: 'Actual Margin P/HR',
    rate_card_margin: 'Rate Card Margin',
    credit_per_hour: 'Credit P/HR',
    total_savings: 'Identified Grey Margin',
    total_pay: 'Worked Pay',
    national_insurance: 'Worked NI',
    pension: 'Worked Pension',
    apprenticeship_levy: 'Worked App Levy',
    wtr: 'WTR',
    holiday_pay_value: 'Paid WTR',
    paid_holiday_employment_cost: 'Paid WTR Employment Cost',
    pension_opt_out: 'Pension Opt Out',
    under_twentyone: 'Under 21 yrs',
    under_twentytwo: 'Under 22 yrs',
    within_twelveweeks: 'Within 12 Weeks',
    other_assignment_pay_value: 'Other Assignment Pay',
    credit_value: 'Credit Value',
    total_cost: 'Total Cost',
    last_date_opt_in: 'Latest Pension Opt In Date',
    pension_status_count: 'Number of Pension Status Changes',
    accrual_value: 'WTR Accrual'
  };
  const downloadCsvData = async (id, week, startDate) => {
    const result = await downloadPayrollData(id);
    if (result && result.ok) {
      const year = startDate && startDate.split('-')[0] ? startDate.split('-')[0] : '0000';
      // utils function to download csv or xlsx
      exportToExcel(
        result.data.payroll_list,
        `Weekly Savings Report Week ${week || '00'} ${year}`,
        ReportMapFields,
        FILE_FORMAT.XLSX
      );
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };
  const downloadDetailedReport = async () => {
    const result = await downloadPayrollDetailsData(selectedPayrollData.payroll_meta_id);
    if (result && result.ok) {
      if (!result.data.detailed_summary) {
        return;
      }
      const { client = '', start_date = '' } = result.data.detailed_summary[0];
      const { employee_id, ...reportMapCopy } = ReportMapFields;
      const headerMap = {
        start_date: 'Week Commencing',
        client: 'Client',
        shift: 'Shift',
        department: 'Department',
        employee_id,
        pay_type: 'Pay Type',
        ...reportMapCopy
      };
      const fileName = `${client}_${selectedPayrollData.site_name}_${selectedPayrollData.agency_name}_${start_date}`;

      // utils function to download csv or xlsx
      exportToExcel(result.data.detailed_summary, fileName, headerMap, FILE_FORMAT.XLSX);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const downloadUploadedTnaSheet = async () => {
    if (selectedPayrollData && selectedPayrollData.uploaded_tna_sheet) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.setAttribute('href', selectedPayrollData.uploaded_tna_sheet);
      a.setAttribute('download', 'Uploaded-TNA-Sheet');
      a.click();
    }
  };

  const getDownloadSampleBtn = (type) => {
    let downloadBtn = '';
    if (
      USER_TYPE.AGENCY_ADMIN === userDetails.user_type_id ||
      USER_TYPE.AGENCY_REGION_ADMIN === userDetails.user_type_id ||
      USER_TYPE.AGENCY_SITE_ADMIN === userDetails.user_type_id
    ) {
      if (
        (type === 'worker' && !isEdit) ||
        (type === 'payroll' && isEdit && !selectedPayrollData.detail_payroll_report)
      ) {
        downloadBtn = (
          <StyledDownloadSample title="Download sample" onClick={downloadBtnClickHandler}>
            <DownloadIcon />
          </StyledDownloadSample>
        );
      }
    }
    return downloadBtn;
  };

  const setAgencyData = (e) => {
    setAgency(e);
    setReload(!reload);
  };

  const handelDateChange = (event) => {
    const client_index = typeof selectweekDay === 'number' ? selectweekDay : dayIndex;
    const current_index = event[0].weekDay.index;
    if (client_index > current_index) {
      const forward_index = client_index - current_index - 1;
      const index = 7 - (client_index - current_index);

      setDateRange([
        new DateObject(
          moment(event[0].toDate(), 'YYYY-MM-DD').subtract(index, 'days').format('YYYY-MM-DD')
        ),
        new DateObject(
          moment(event[0].toDate(), 'YYYY-MM-DD').add(forward_index, 'days').format('YYYY-MM-DD')
        )
      ]);
    } else {
      const index = current_index - client_index;
      const forward_index = 7 - index - 1;
      setDateRange([
        new DateObject(
          moment(event[0].toDate(), 'YYYY-MM-DD').subtract(index, 'days').format('YYYY-MM-DD')
        ),
        new DateObject(
          moment(event[0].toDate(), 'YYYY-MM-DD').add(forward_index, 'days').format('YYYY-MM-DD')
        )
      ]);
    }
  };

  useEffect(() => {
    setDateRange();
  }, [selected_client_id]);

  useEffect(() => {
    setReload(!reload);
  }, [selected_client_id, selected_site_id, selected_start_date, selected_end_date]);

  // const lastField = columns.find((item) => {
  //   item.field = 'ClearVue'
  // })

  // useEffect(() => {
  //   if (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN) {

  //   }
  // }, [])
  return (
    <>
      <PerformanceModal
        week={isEdit ? selectedPayrollData.week : currentWeek}
        clientName={
          userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
          userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
          userDetails.user_type_id === USER_TYPE.REGION_ADMIN
            ? clientName
            : selected_client_name || clientName
        }
        clientId={
          userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
          userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
          userDetails.user_type_id === USER_TYPE.REGION_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
            ? user_details.client_id
            : selected_client_id
        }
        siteId={
          userDetails.user_type_id === USER_TYPE.SITE_ADMIN ? userDetails.site_id : selected_site_id
        }
        site={
          userDetails.user_type_id === USER_TYPE.SITE_ADMIN
            ? userDetails.name
            : userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
            ? sitesList[0]?.name
            : selected_site_name
        }
        dateRange={dateRange}
        isWorkerPerformanceModalOpen={openWorkerPerformanceModal}
        onCloseWorkerPerformance={onCloseWorkerPerformance}
      />
      <AlertDialogComponent
        isOpen={openPayrollDeleteModal}
        onClose={onCloseDeleteModal}
        title={constants.ATTENDANCE.DELETE_PAYROLL}
        body={
          <Box>
            {tapBtnClicked === true
              ? `Are you sure you want delete TAP data for week ?`
              : `Are you sure you want delete payroll data for week ${weekData} ?`}
          </Box>
        }
        negative={
          <Button
            bg="main.secondary"
            mr={3}
            _hover={{ bg: 'main.secondary' }}
            color="main.black"
            onClick={onCloseDeleteModal}>
            {constants.ATTENDANCE.CANCEL_BUTTON_TEXT}
          </Button>
        }
        positive={
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            onClick={tapBtnClicked === true ? deleteTapSheet : confirmDeletePayroll}>
            {constants.ATTENDANCE.CONFIRM_BUTTON_TEXT}
          </Button>
        }
      />
      <StyledAttendanceContainer bg="main.secondary">
        {isPageLoader ? <Loader /> : null}
        <ModalComponent
          isOpen={isUploadModalOpen}
          onClose={() => onCloseUploadModal()}
          modalTitle={
            tapBtnClicked
              ? constants.ATTENDANCE.UPDATE_TAP_TEXT
              : constants.ATTENDANCE.ATTENDANCE_UPLOAD_HEADER_TEXT
          }
          modalContent={
            <>
              <Flex>
                <Box w="240px" mt={2}>
                  <Text>Choose Week</Text>
                </Box>
                <Box flex="1">
                  <InputComponent
                    lable=""
                    placeholder="Choose Week"
                    value={isEdit ? selectedPayrollData.week : `${currentWeek}`}
                    validationObj={{ isvalid: true }}
                    m={0}
                    disabled
                  />
                </Box>
              </Flex>
              <Flex>
                <Box w="240px" mt={2}>
                  <Text>Client Name</Text>
                </Box>
                <Box flex="1">
                  <InputComponent
                    lable=""
                    placeholder="Client Name"
                    value={
                      isEdit ? selectedPayrollData.client_name : selected_client_name || clientName
                    }
                    validationObj={{ isvalid: true }}
                    disabled
                  />
                </Box>
              </Flex>
              <Flex>
                <Box w="240px" mt={2}>
                  <Text>Site</Text>
                </Box>
                <Box flex="1">
                  <InputComponent
                    lable=""
                    placeholder="Site"
                    value={
                      isEdit
                        ? selectedPayrollData.site_name
                        : userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
                        ? sitesList[0]?.name
                        : selected_site_name
                    }
                    validationObj={{ isvalid: true }}
                    disabled
                  />
                </Box>
              </Flex>

              {(!isEdit || confirmBtnClicked) && (
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }} mb={4}>
                  <Box>
                    {tapBtnClicked ? (
                      <StyledUploadLabel>
                        TAP Example {getDownloadSampleBtn('worker')}
                      </StyledUploadLabel>
                    ) : (
                      <StyledUploadLabel>
                        Worker Clock Report {getDownloadSampleBtn('worker')}
                      </StyledUploadLabel>
                    )}
                  </Box>
                  <Box>
                    <StyledUploadFileButton bg="main.primary" for="clock-report">
                      Add File
                    </StyledUploadFileButton>
                    <input
                      type="file"
                      id="clock-report"
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        handelWorkerFileChange(event);
                      }}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  </Box>
                  {/*  For TAP */}
                  {tapBtnClicked === true && (
                    <Flex>
                      <Button
                        title="Download Uploaded TAP Sheet"
                        bg="main.semiPrimary"
                        color="main.white"
                        _hover={{ bg: 'main.primary' }}
                        _active={{
                          bg: 'main.semiPrimary',
                          borderColor: 'main.primary'
                        }}
                        padding="6px"
                        size="sm"
                        mr="8px"
                        onClick={() => downloadTapBtnClickHandler()}>
                        <DownloadIcon />
                      </Button>
                      <Button
                        bg="main.semiPrimary"
                        color="main.white"
                        _hover={{ bg: 'main.primary' }}
                        _active={{
                          bg: 'main.semiPrimary',
                          borderColor: 'main.primary'
                        }}
                        padding="6px"
                        size="sm"
                        onClick={() => {
                          setopenPayrollDeleteModal(true);
                        }}>
                        <Image src={DeleteIcon} height={4} />
                      </Button>
                    </Flex>
                  )}
                </Flex>
              )}
              <Flex
                style={
                  tapBtnClicked === true
                    ? { justifyContent: 'space-between', alignItems: 'center' }
                    : { alignItems: 'center' }
                }
                mb={4}>
                {(USER_TYPE.AGENCY_ADMIN === userDetails.user_type_id ||
                  USER_TYPE.CLIENT_ADMIN === userDetails.user_type_id ||
                  USER_TYPE.SITE_ADMIN === userDetails.user_type_id ||
                  USER_TYPE.AGENCY_REGION_ADMIN === userDetails.user_type_id ||
                  USER_TYPE.AGENCY_SITE_ADMIN === userDetails.user_type_id) &&
                  selectedPayrollData &&
                  selectedPayrollData.time_and_attendance_status && (
                    <Grid templateColumns="repeat(3, 1fr)" gap={4} width="100%" mt={4}>
                      <GridItem
                        style={{
                          alignItems: 'center',
                          justifySelf: 'flex-end'
                        }}>
                        <Text fontWeight="semibold">Summary Report</Text>

                        <Box
                          style={{
                            alignItems: 'center',
                            justifySelf: 'flex-end'
                          }}>
                          <Button
                            mt="4"
                            title="Download Summary Report"
                            bg="main.semiPrimary"
                            color="main.white"
                            _hover={{ bg: 'main.primary' }}
                            _active={{
                              bg: 'main.semiPrimary',
                              borderColor: 'main.primary'
                            }}
                            onClick={() =>
                              downloadCsvData(
                                selectedPayrollData.payroll_meta_id,
                                selectedPayrollData.week,
                                selectedPayrollData.start_date
                              )
                            }>
                            <DownloadIcon />
                          </Button>
                        </Box>
                      </GridItem>

                      <GridItem
                        style={{
                          alignItems: 'center',
                          justifySelf: 'flex-end'
                        }}>
                        <Text fontWeight="semibold">Detailed Report</Text>

                        <Box
                          style={{
                            alignItems: 'center',
                            justifySelf: 'flex-end'
                          }}>
                          <Button
                            mt="4"
                            title="Download Detailed Report"
                            bg="main.semiPrimary"
                            color="main.white"
                            _hover={{ bg: 'main.primary' }}
                            _active={{
                              bg: 'main.semiPrimary',
                              borderColor: 'main.primary'
                            }}
                            onClick={() => {
                              downloadDetailedReport();
                            }}>
                            <DownloadIcon />
                          </Button>
                        </Box>
                      </GridItem>

                      <GridItem
                        style={{
                          alignItems: 'center',
                          justifySelf: 'flex-end'
                        }}>
                        <Text fontWeight="semibold">Uploaded T&A File</Text>

                        <Flex alignItems="center" mt="4">
                          {tapBtnClicked === true ? null : (
                            <Box mr="2">
                              <Text fontWeight="medium">
                                {isEdit
                                  ? selectedPayrollData.time_and_attendance_created_at
                                    ? moment(
                                        selectedPayrollData.time_and_attendance_created_at,
                                        'YYYY-MM-DD'
                                      ).format('DD/MM/YYYY')
                                    : 'Date of uploaded'
                                  : 'Date of uploaded'}
                              </Text>
                              <Text fontSize="sm" color="green.500">
                                {isEdit
                                  ? selectedPayrollData.time_and_attendance_status
                                    ? selectedPayrollData.time_and_attendance_status
                                    : 'No Status'
                                  : 'No Status'}
                              </Text>
                            </Box>
                          )}
                          <Button
                            title="Download Uploaded T&A File"
                            bg="main.semiPrimary"
                            color="main.white"
                            _hover={{ bg: 'main.primary' }}
                            _active={{
                              bg: 'main.semiPrimary',
                              borderColor: 'main.primary'
                            }}
                            onClick={() => downloadUploadedTnaSheet()}>
                            <DownloadIcon />
                          </Button>
                        </Flex>
                      </GridItem>
                    </Grid>
                  )}
              </Flex>
              <Flex style={{ justifyContent: 'space-between' }} mb={1}>
                <Flex style={{ justifyContent: 'flex-start' }}>
                  {selectedPayrollData?.uploaded_performance_sheet && (
                    <Box w="150px" textAlign="center">
                      <Tooltip label={selectedPayrollData.uploaded_performance_sheet}>
                        <Text isTruncated>{selectedPayrollData.uploaded_performance_sheet}</Text>
                      </Tooltip>
                    </Box>
                  )}
                  {selectedPayrollData?.uploaded_tap_sheet && (
                    <Box w="150px" textAlign="center">
                      <Tooltip label={selectedPayrollData.uploaded_tap_sheet}>
                        <Text isTruncated>{selectedPayrollData.uploaded_tap_sheet}</Text>
                      </Tooltip>
                    </Box>
                  )}
                </Flex>
              </Flex>
              {uploadedWorkerFileName ? <Box>{uploadedWorkerFileName}</Box> : null}
            </>
          }
          positive={
            selectedPayrollData && selectedPayrollData.time_and_attendance_status
              ? ''
              : getConfirmedBtn()
          }
        />
        <Card>
          {userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN && tnaFtp && (
            <Box>
              <Flex>
                <Box width="210px" mr={4}>
                  <AgencyDropdown agency={agency} refresh={reload} setAgency={setAgencyData} />
                </Box>
                <Box ml={4}>
                  <Button
                    mt={
                      (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                        userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN) &&
                      '25px'
                    }
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    onClick={async () => {
                      setFtpLoader(true);
                      try {
                        const result = await enableFTPLookup(user_details.client_id);
                        if (result && result.ok) {
                          toast({
                            ...TOAST_SETTINGS.SETTINGS,
                            status: TOAST_SETTINGS.SUCCESS,
                            title: result.message
                          });
                        } else {
                          toast({
                            ...TOAST_SETTINGS.SETTINGS,
                            status: TOAST_SETTINGS.ERROR,
                            title: result.error
                          });
                        }
                      } finally {
                        setFtpLoader(false);
                      }
                    }}
                    disabled={ftpLoader}
                    width="250px">
                    {ftpLoader ? (
                      <Flex alignItems="center">
                        <Spinner size="sm" color="white" mr={2} />
                        <Text>Processing...</Text>
                      </Flex>
                    ) : (
                      'Trigger FTP Timesheet Upload'
                    )}
                  </Button>
                </Box>
              </Flex>
            </Box>
          )}

          <StyledAttendanceHeaderContainer>
            <Flex
              marginTop={total_assignment_pay === 1 || tapValue === 1 ? 5 : 0}
              alignItems="flex-end">
              {permission && permission.includes('create') && (
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={openUploadModal}
                  disabled={!selected_site_id || !dateRange}
                  mr={4}>
                  {constants.ATTENDANCE.ADD_BUTTON_TEXT}
                </Button>
              )}
              <Box width="210px" mr={4}>
                <p className="input-label">Select Date*</p>
                <DatePicker
                  format="DD/MM/YYYY"
                  inputClass="date-picker"
                  range
                  placeholder="Select Week Range"
                  maxDate={new Date()}
                  editable={false}
                  onChange={handelDateChange}
                  value={dateRange}
                  className="booking-datepicker"
                  weekStartDayIndex={typeof selectweekDay === 'number' ? selectweekDay : dayIndex}
                />
              </Box>
              {permission &&
                permission.includes('create') &&
                (total_assignment_pay === 1 || tapValue === 1) && (
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    onClick={() => openUploadModal(true)}
                    disabled={!selected_site_id || !dateRange}>
                    {constants.ATTENDANCE.TAP_BUTTON_TEXT}
                  </Button>
                )}
              <Box width="210px" ml={4}>
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={onOpenWorkerPerformanceModal}
                  disabled={
                    USER_TYPE.AGENCY_REGION_ADMIN === userDetails.user_type_id ||
                    USER_TYPE.AGENCY_SITE_ADMIN === userDetails.user_type_id ||
                    USER_TYPE.AGENCY_ADMIN === userDetails.user_type_id
                      ? !selected_site_id || !dateRange
                      : !dateRange
                  }>
                  Add Performance
                </Button>
              </Box>
            </Flex>
          </StyledAttendanceHeaderContainer>
          <ServerSideDatatable
            pageSize={5}
            initialSortKey="start_date"
            column={columns}
            onPaginate={retrievePayrollData}
            afterPaginateData={() => setIsPageLoader(false)}
            pagination
            pages={[5, 10, 15]}
            dataKey="payroll_list"
            refresh={reload}
          />
        </Card>
      </StyledAttendanceContainer>{' '}
    </>
  );
};

Attendance.propTypes = {};

export default Attendance;
