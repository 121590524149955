/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Text,
  FormErrorMessage
} from '@chakra-ui/react';
import DatePicker from 'react-multi-date-picker';
import moment from 'moment';
import ModalComponent from '../../../../components/Modal';
import Dropdown from '../../../../components/Dropdown';
import InputComponent from '../../../../components/Input';
import { allowTwoDecimalNumbersInRange, deepClone, validateForm } from '../../../../utils/helper';
import constants, { ADD_YEARLY_RULE } from '../../../../constants';

const calculateEndDateInfo = (startDate, weeks) => {
  const date = moment(startDate.format('YYYY/MM/DD'));
  date.add(+weeks * 7 - 1, 'days');
  return {
    date,
    day: date.format('dddd')
  };
};
const getFirstWeekdayOfMarch = (year, weekday) => {
  const date = moment(`${year}/03/01`);
  while (date.day() !== weekday) {
    date.add(1, 'day');
  }
  return date;
};

const FinancialRuleAddEdit = function ({
  isOpen,
  onClose,
  onSave,
  clientWeekDay,
  defaultDate,
  isEdit,
  selectedData,
  selectedIndex,
  rulesExists
}) {
  const todayDate = moment();
  const [userCurrentDate, setUserCurrentDate] = useState();
  const [validationObject, setValidationObject] = useState(deepClone(ADD_YEARLY_RULE));
  const [refreshFlag, setRefreshFlag] = useState(false);
  const calendarRef = useRef();

  const weekTotalOptions = [
    { value: '52', label: '52 Weeks' },
    { value: '53', label: '53 Weeks' }
  ];
  const weekDayMap = new Map([
    ['SUN', { idx: 0, display: 'Sunday' }],
    ['MON', { idx: 1, display: 'Monday' }],
    ['TUE', { idx: 2, display: 'Tuesday' }],
    ['WED', { idx: 3, display: 'Wednesday' }],
    ['THU', { idx: 4, display: 'Thuesday' }],
    ['FRI', { idx: 5, display: 'Friday' }],
    ['SAT', { idx: 6, display: 'Saturday' }]
  ]);

  const [formData, setFormData] = useState({
    weekStartDay: '',
    weekStartDate: '',
    selectedWeeks: null,
    weekEndDay: '',
    weekEndDate: '',
    niPercentage: '',
    niThreshold: '',
    pensionPercentage: '',
    pensionThreshold: '',
    appLevyPercentage: ''
  });
  const resetForm = () => {
    clientWeekDay = null;
    setFormData({
      weekStartDay: '',
      weekStartDate: '',
      selectedWeeks: null,
      weekEndDay: '',
      weekEndDate: '',
      niPercentage: '',
      niThreshold: '',
      pensionPercentage: '',
      pensionThreshold: ''
    });
    setValidationObject(deepClone(ADD_YEARLY_RULE));
  };
  useEffect(() => {
    if (clientWeekDay) {
      setFormData((oldData) => ({
        ...oldData,
        weekStartDay: weekDayMap.get(clientWeekDay).display
      }));

      if (!defaultDate) {
        const year = todayDate.year();
        const date = getFirstWeekdayOfMarch(year, weekDayMap.get(clientWeekDay).idx);
        setUserCurrentDate(date);
      } else {
        let date = moment(defaultDate);
        if (!isEdit) {
          date.add(1, 'day');
        } else {
          date = moment(selectedData?.start_date);
        }
        setUserCurrentDate(date);
      }
    }
    if (isEdit && selectedData) {
      setFormData((oldData) => ({
        ...oldData,
        selectedWeeks: weekTotalOptions.find(
          (option) => +option.value === selectedData.total_weeks
        ),
        niPercentage: selectedData.ni_percent,
        niThreshold: selectedData.ni_threshold,
        pensionPercentage: selectedData.pension_percent,
        pensionThreshold: selectedData.pension_threshold,
        appLevyPercentage: selectedData.app_levy_percent
      }));
    }
  }, [isOpen, defaultDate]);

  useEffect(() => {
    if (userCurrentDate) {
      setFormData((oldData) => ({
        ...oldData,
        weekStartDate: userCurrentDate
      }));
    }
  }, [userCurrentDate]);
  const setEndDateNDay = () => {
    if (formData.weekStartDate && formData.selectedWeeks) {
      const [validatedObject] = validateForm(formData, validationObject);
      setValidationObject(validatedObject);
      const endDateDetails = calculateEndDateInfo(
        formData.weekStartDate,
        formData.selectedWeeks.value
      );
      if (
        !formData.weekEndDate ||
        endDateDetails.date.format('DD/MM/YYYY') !== formData.weekEndDate?.format('DD/MM/YYYY')
      ) {
        const newData = { weekEndDay: endDateDetails.day, weekEndDate: endDateDetails.date };
        setFormData((oldData) => ({ ...oldData, ...newData }));
      }
    }
  };
  useEffect(() => {
    setEndDateNDay();
  }, [formData]);

  const handleWeekTotalChange = (selectedOption) => {
    const newData = { selectedWeeks: selectedOption };
    setFormData((oldData) => ({
      ...oldData,
      ...newData
    }));
  };

  const handleDateChange = (value) => {
    const newData = { weekStartDate: moment(value.toString(), 'DD/MM/YYYY') };
    setFormData((oldData) => ({ ...oldData, ...newData }));
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleFormSubmit = () => {
    const [validatedObject, isFormValid] = validateForm(formData, validationObject);
    setValidationObject(validatedObject);
    setRefreshFlag(!refreshFlag);
    if (isFormValid) {
      onSave(formData);
      resetForm();
    }
  };
  const handleInputChange = (field, value) => {
    setFormData((oldData) => ({ ...oldData, [field]: value }));
  };
  const getForm = () => {
    const isDayDisabled = (date) => date.weekDay.index !== weekDayMap.get(clientWeekDay)?.idx;

    return (
      <Grid templateColumns="repeat(4, 1fr)" gap={4} p={4}>
        {isEdit && (
          <GridItem colSpan={4}>
            <Text as="span" fontSize="sm" color="red">
              Note: Editing total weeks or date may result in the deletion of the following rule, if
              applicable.
            </Text>
          </GridItem>
        )}
        <GridItem colSpan={2}>
          <FormControl>
            <InputComponent
              lable="Week Start Day"
              placeholder="Week Start Day"
              value={formData.weekStartDay}
              disabled
              templatevalidation
              style={{ marginTop: '0.5rem' }}
              labelFontSize="md"
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl isInvalid={!validationObject.weekStartDate.isvalid}>
            <FormLabel color="main.primary" fontWeight="normal">
              Start Date
            </FormLabel>
            <DatePicker
              placeholder="Start Date"
              format="DD/MM/YYYY"
              inputClass={
                (rulesExists && !isEdit) || (!!defaultDate && selectedIndex !== 0)
                  ? 'date-picker-disabled'
                  : 'date-picker'
              }
              ref={calendarRef}
              value={formData.weekStartDate ? formData.weekStartDate?.format('DD/MM/YYYY') : ''}
              weekStartDayIndex={weekDayMap.get(clientWeekDay)?.idx}
              onChange={handleDateChange}
              mapDays={({ date }) => ({ disabled: isDayDisabled(date) })}
              disabled={(rulesExists && !isEdit) || (!!defaultDate && selectedIndex !== 0)}>
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => calendarRef.current.closeCalendar()}
                size="xs"
                mb={4}>
                {constants.DASHBOARD_VIEW.CLOSE_BUTTON_TEXT}
              </Button>
            </DatePicker>
            {!validationObject.weekStartDate.isvalid && (
              <FormErrorMessage>{validationObject.weekStartDate.errorMessage}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl isInvalid={!validationObject?.selectedWeeks.isvalid}>
            <Dropdown
              label="Total Weeks"
              labelFontSize="md"
              options={weekTotalOptions}
              value={formData.selectedWeeks}
              onSelect={handleWeekTotalChange}
              placeholder="Select Week Total"
              isSearchable={false}
              styles={{ marginTop: '0.5rem' }}
              isError={!validationObject?.selectedWeeks.isvalid}
            />
            {!validationObject?.selectedWeeks.isvalid && (
              <FormErrorMessage>{validationObject?.selectedWeeks.errorMessage}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl>
            <InputComponent
              lable="Week End Day"
              placeholder="Week End Day"
              value={formData.weekEndDay}
              disabled
              templatevalidation
              style={{ marginTop: '0.5rem' }}
              labelFontSize="md"
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl>
            <InputComponent
              lable="End Date"
              placeholder="End Date"
              value={formData.weekEndDate && formData.weekEndDate.format('DD/MM/YYYY')}
              disabled
              templatevalidation
              style={{ marginTop: '0.5rem' }}
              labelFontSize="md"
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl>
            <InputComponent
              type="number"
              lable="NI Percent"
              placeholder="NI Percent"
              value={formData.niPercentage}
              style={{ marginTop: '0.5rem' }}
              labelFontSize="md"
              onChange={(value) => {
                handleInputChange('niPercentage', allowTwoDecimalNumbersInRange(value, 0, 100));
              }}
              validationObj={validationObject.niPercentage}
              refresh={refreshFlag}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl>
            <InputComponent
              type="number"
              lable="NI Threshold"
              placeholder="NI Threshold"
              value={formData.niThreshold}
              style={{ marginTop: '0.5rem' }}
              labelFontSize="md"
              onChange={(value) => {
                handleInputChange('niThreshold', allowTwoDecimalNumbersInRange(value, 0, 999));
              }}
              validationObj={validationObject.niThreshold}
              refresh={refreshFlag}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={4}>
          <FormControl>
            <InputComponent
              type="number"
              lable="App Levy Percent"
              placeholder="App Levy Percent"
              labelFontSize="md"
              value={formData.appLevyPercentage}
              style={{ marginTop: '0.5rem' }}
              onChange={(value) => {
                handleInputChange(
                  'appLevyPercentage',
                  allowTwoDecimalNumbersInRange(value, 0, 100)
                );
              }}
              validationObj={validationObject.appLevyPercentage}
              refresh={refreshFlag}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl>
            <InputComponent
              type="number"
              lable="Pension Percent"
              placeholder="Pension Percent"
              value={formData.pensionPercentage}
              style={{ marginTop: '0.5rem' }}
              labelFontSize="md"
              onChange={(value) => {
                handleInputChange(
                  'pensionPercentage',
                  allowTwoDecimalNumbersInRange(value, 0, 100)
                );
              }}
              validationObj={validationObject.pensionPercentage}
              refresh={refreshFlag}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl>
            <InputComponent
              type="number"
              lable="Pension Threshold"
              placeholder="Pension Threshold"
              value={formData.pensionThreshold}
              style={{ marginTop: '0.5rem' }}
              labelFontSize="md"
              onChange={(value) => {
                handleInputChange('pensionThreshold', allowTwoDecimalNumbersInRange(value, 0, 999));
              }}
              validationObj={validationObject.pensionThreshold}
              refresh={refreshFlag}
            />
          </FormControl>
        </GridItem>
      </Grid>
    );
  };

  return (
    <ModalComponent
      specifySize="xl"
      isOpen={isOpen}
      onClose={handleClose}
      modalTitle="Add Financial Rule"
      modalContent={getForm()}
      negative={
        <Button onClick={handleClose} bg="main.secondary" mr={3} color="main.black">
          Cancel
        </Button>
      }
      positive={
        <Button
          onClick={handleFormSubmit}
          bg="main.semiPrimary"
          color="main.white"
          _hover={{ bg: 'main.primary' }}
          mr={3}
          _active={{
            bg: 'main.semiPrimary',
            borderColor: 'main.primary'
          }}>
          {isEdit ? 'Update' : 'Save'}
        </Button>
      }
    />
  );
};

export default FinancialRuleAddEdit;
