/* eslint-disable no-use-before-define */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Center, CloseButton, Flex, Text, useToast } from '@chakra-ui/react';
import StyledInputWrapper from '../../components/Forms/Forms.styled';
import ClientSideDatatable from '../../components/ClientSideDatatable';
import constants, {
  ADD_DEPARTMENT_VALIDATIONS,
  PATH,
  TOAST_SETTINGS,
  USER_TYPE,
  PAGE_TITLE,
  CUSTOM_TOAST_DURATION
} from '../../constants';
import {
  addDepartment,
  getDepartments,
  updateDepartment
} from '../../redux/action/department.action';
import {
  getActivityTopCardData,
  getWorkforceTopCardLOSData,
  getWorkforceTopCardShiftUtiData,
  getWorkforceTopCardPoolUtiData,
  getLeaverTopCardLOS,
  getLeaverTopCardCounts,
  getActivityBottomCardShiftUti,
  getActivityBottomCardHeadCount,
  getActivityBottomCardSpend,
  getActivityBottomCardAverageHours,
  getWorkforceShiftUti,
  getWorkforceLOS,
  getLeaverNSR,
  getLeaverCount,
  getLeaverLOS,
  getSiteRatingData,
  getAgencyRatingData,
  getClientRatingData,
  getHeaderData,
  getLeaverAnalysisData
} from '../../redux/action/departmentDashboard.action';
import {
  deepClone,
  isDashboardBottomCardVisible,
  replaceEmojiInInputField,
  restrictSomeSpecialCharacter,
  validateForm
} from '../../utils/helper';
import ModalComponent from '../../components/Modal';
import InputComponent from '../../components/Input';
import Card from '../../components/Card';
import DepartmentDropdownHeader from '../../components/Forms/DepartmentDropdownHeader';

import StyledDepartmentsContainer, { StyledDiv, StyledItem } from './Department.styled';
import Loader from '../../components/Loader';
import { getModulePermissions } from '../../utils/hooks';
import DashboardTopCard from '../../components/DashboardTopCard';
import DashboardBottomCard from '../../components/DashboardBottomCard';
import DashboardRatingCard from '../../components/DashboardRatingCard';
import Dropdown from '../../components/Dropdown';
import { getRegions } from '../../redux/action/region.action';
import { getSites } from '../../redux/action/site.action';
import { selectPerformanceDepartment } from '../../redux/actions/performanceDashboard.action';
import { getShifts } from '../../redux/action/shift.action';

const Department = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.DEPARTMENT;
  }, []);
  const toast = useToast();
  const { userData, user_details } = useSelector((state) => state.authentication);
  const {
    selected_client_id,
    selected_start_date,
    selected_end_date,
    is_restricted,
    selected_performance_department_id,
    selected_performance_region_id,
    selected_performance_site_id
  } = useSelector((state) => state.agency);

  const getClientId = () => {
    switch (user_details.user_type_id) {
      case USER_TYPE.CLIENT_ADMIN:
        return userData.client_id;
      case USER_TYPE.AGENCY_ADMIN:
      case USER_TYPE.AGENCY_REGION_ADMIN:
      case USER_TYPE.AGENCY_SITE_ADMIN:
        return selected_client_id || userData.client_id;
      default:
        return selected_client_id || userData.client_id;
    }
  };

  const getRegionId = () => {
    switch (user_details.user_type_id) {
      case USER_TYPE.SUPER_ADMIN:
        return selected_performance_region_id;
      case USER_TYPE.CLIENT_ADMIN:
        return selected_performance_region_id;
      case USER_TYPE.AGENCY_ADMIN:
        return selected_performance_region_id;
      case USER_TYPE.AGENCY_REGION_ADMIN:
        return user_details.region_id;
      case USER_TYPE.AGENCY_SITE_ADMIN:
        return selected_performance_region_id;
      case USER_TYPE.SITE_ADMIN:
        return null;
      default:
        return selected_performance_region_id || userData.region_id;
    }
  };

  const getSiteId = () => {
    switch (user_details.user_type_id) {
      case USER_TYPE.SITE_ADMIN:
      case USER_TYPE.AGENCY_SITE_ADMIN:
        return user_details.site_id;
      default:
        return selected_performance_site_id || userData.site_id || null;
    }
  };

  const permission = getModulePermissions(PATH.DEPARTMENT.CODE);
  const [departmentData, setDepartmentData] = useState([]);

  const [refresh, setRefresh] = useState(false);
  const [reload, setReload] = useState(false);
  const [inModalOpan, setModalOpen] = useState(false);
  const [validations, setValidations] = useState(deepClone(ADD_DEPARTMENT_VALIDATIONS));
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [isPageLoader, setIsPageLoader] = useState(false);

  const [data, setData] = useState({
    name: '',
    cost_centre: ''
  });

  const [regionData, setRegionData] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({});

  const [siteData, setSiteData] = useState([]);
  const [selectedSite, setSelectedSite] = useState({});
  const [selectedRegionSiteList, setSelectedRegionSiteList] = useState([]);

  const [association, setAssociations] = useState([]);

  const [shouldDepartmentsRefresh, setShouldDepartmentsRefresh] = useState(false);
  const dispatch = useDispatch();

  const [shifts, setShifts] = useState([]);
  const [selectedShift, setSelectedShift] = useState({});
  const isFirstRender = useRef(true);

  const getDepartmentsData = async () => {
    if (getClientId()) {
      setIsPageLoader(true);
      const result = await getDepartments(getClientId(), getSiteId(), getRegionId());

      setIsPageLoader(false);
      if (result && result.ok) {
        setDepartmentData(result.data.department_list);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    }
  };

  useEffect(async () => {
    getDepartmentsData();
  }, [
    selected_client_id,
    selected_start_date,
    selected_end_date,
    is_restricted,
    selected_performance_department_id,
    selected_performance_region_id,
    selected_performance_site_id
  ]);

  useEffect(async () => {
    if (user_details.user_type_id !== USER_TYPE.AGENCY_ADMIN) getDepartmentsData();
    if (permission && permission.includes('create')) {
      const regionsData = await getRegions(getClientId());
      if (regionsData && regionsData.ok) {
        setRegionData(regionsData.data?.region_details);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: regionsData.error
        });
      }
    }
  }, []);

  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        if (selectedRegion && selectedRegion.value) {
          const sites = await getSites(
            selected_client_id || userData.client_id,
            selectedRegion.value
          );

          setSiteData(sites?.data?.sites);
        }
      } catch (error) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: error
        });
      }
    };

    fetchSiteData();
  }, [selectedRegion]);

  useEffect(() => {
    if (selectedRegion.value && selectedSite.value) {
      setSelectedRegionSiteList((prevList) => {
        const newList = [...prevList];
        const existingEntryIndex = newList.findIndex(
          (item) =>
            item[selectedRegion.value] &&
            item[selectedRegion.value].region_id === selectedRegion.value
        );

        if (existingEntryIndex !== -1) {
          // Entry already exists for the selected region ID and region name
          const existingEntry = newList[existingEntryIndex];
          const existingSites = existingEntry[selectedRegion.value].sites;
          const existingSiteIds = existingSites.map((site) => site.siteId);

          // Check if the selected site ID already exists
          if (!existingSiteIds.includes(selectedSite.value)) {
            // Check if the selected site label already exists (optional)
            const existingSiteLabels = existingSites.map((site) => site[selectedSite.value]);
            if (!existingSiteLabels.includes(selectedSite.label)) {
              // Add the new site to the existing entry
              existingEntry[selectedRegion.value].sites.push({
                [selectedSite.value]: selectedSite.label
              });
              // Sort the sites array based on siteId for consistency
              existingEntry[selectedRegion.value].sites.sort(
                (a, b) => parseInt(a.siteId, 10) - parseInt(b.siteId, 10)
              );
              newList[existingEntryIndex] = existingEntry;
            }
          }
        } else {
          // Entry doesn't exist, create a new one
          newList.push({
            [selectedRegion.value]: {
              region: selectedRegion.label,
              region_id: selectedRegion.value,
              sites: [
                {
                  [selectedSite.value]: selectedSite.label
                }
              ]
            }
          });
        }

        return newList;
      });

      setSelectedSite({});
    }
  }, [selectedRegion, selectedSite, selectedRegionSiteList]);

  const addNewAssociation = (newAssociation) => {
    setAssociations((prevAssociations) => {
      const existingAssociations = [...prevAssociations];

      // Check if the new association already exists
      const existingIndex = existingAssociations.findIndex(
        (assoc) =>
          assoc.site_id === newAssociation.site_id && assoc.region_id === newAssociation.region_id
      );

      if (existingIndex === -1) {
        // Add the new association
        existingAssociations.push(newAssociation);
      } else {
        // Update the existing association (if needed)
        existingAssociations[existingIndex] = newAssociation;
      }

      return existingAssociations;
    });
  };

  // Call addNewAssociation function with new data
  useEffect(() => {
    if (selectedRegion.value && selectedSite.value) {
      const newAssociation = {
        site_id: selectedSite.value,
        site_name: selectedSite.label,
        region_id: selectedRegion.value,
        region_name: selectedRegion.label
      };
      addNewAssociation(newAssociation);
    }
  }, [selectedRegion, selectedSite]);

  const onModalClose = () => {
    setModalOpen(false);
    setEdit(false);
    setRefresh(!refresh);
    setData({
      name: ''
    });
    setValidations(deepClone(ADD_DEPARTMENT_VALIDATIONS));
    setSelectedRegion('');
    setSelectedSite('');
    setSiteData([]);
    setSelectedRegionSiteList([]);
  };

  const saveRegion = async () => {
    data.name = data.name.trim();
    const [validationObject, isFormValid] = validateForm({ ...data }, validations);

    setValidations(validationObject);
    if (isFormValid) {
      let result = null;
      if (edit) {
        const modifiedData = association.map((item) => {
          const { site_id, region_id, siteName, regionName, ...rest } = item;
          return {
            ...rest,
            site_id,
            region_id
          };
        });
        result = await updateDepartment(id, {
          ...data,
          client_id: selected_client_id || userData.client_id,
          associations: modifiedData
        });
        if (result && result.ok) {
          if (id === selected_performance_department_id) {
            setShouldDepartmentsRefresh((prev) => !prev);
            dispatch(selectPerformanceDepartment(selected_performance_department_id, data.name));
          }
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
          setEdit(false);
          getDepartmentsData();
          onModalClose();
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      } else {
        let addAssociation = [];
        if (selectedRegionSiteList) {
          addAssociation = selectedRegionSiteList.flatMap((item) => {
            // Initialize an array to store associations
            const regionAssociations = [];

            // Iterate through each region and its sites
            Object.values(item).forEach((regionInfo) => {
              const { regionId, region, sites } = regionInfo;

              // Iterate through each site in the region
              sites.forEach((site) => {
                const siteId = Object.keys(site)[0];
                const siteName = Object.values(site)[0];

                // Create a unique association object and add it to the array
                regionAssociations.push({
                  site_id: siteId,
                  region_id: regionId,
                  region_name: region,
                  site_name: siteName
                });
              });
            });

            return regionAssociations;
          });
        }
        result = await addDepartment({
          ...data,
          client_id: selected_client_id || userData.client_id,
          associations: addAssociation
        });
        if (result && result.ok) {
          setId(result.data?.site_id);
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
          onModalClose();
          setEdit(false);
          getDepartmentsData();
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      }
      setReload(!reload);
    } else {
      setRefresh(!refresh);
      setReload(!reload);
    }
  };

  const editData = (rowId, row) => {
    setEdit(true);
    setData({
      name: row.name,
      cost_centre: row.costCentre || ''
    });
    setAssociations(row.associations);
    setModalOpen(true);
    setId(rowId);
  };

  const [column, setColumn] = useState([
    {
      label: 'Name',
      field: 'name',
      sort: false
    }
  ]);

  useEffect(() => {
    if (permission && permission.includes('edit')) {
      setColumn([
        ...column,
        {
          label: 'Action',
          field: 'id',
          sort: false,
          customRow: (cell, row) => {
            return (
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => editData(cell, row)}>
                {constants.DEPARTMENT.EDIT_BUTTON_TEXT}
              </Button>
            );
          }
        }
      ]);
    }
  }, [permission]);

  useEffect(async () => {
    if (user_details.user_type_id === USER_TYPE.AGENCY_ADMIN) await getDepartmentsData();
    setReload(!reload);
  }, [selected_client_id]);

  const [isCurrentWeek, setIsCurrentWeek] = useState(true);
  useEffect(() => {
    setIsCurrentWeek(() => isDashboardBottomCardVisible(selected_start_date, selected_end_date));
  }, [selected_start_date, selected_end_date]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [headerData, setHeaderData] = useState(null);
  const [ratingData, setratingData] = useState(null);
  const [siteRatingData, setSiteRatingData] = useState(null);
  const [agencyRatingData, setAgencyRatingData] = useState(null);
  const [clientRatingData, setClientRatingData] = useState(null);
  const [leaversData, setLeaversData] = useState(null);
  const [activityTopCardData, setActivityTopCardData] = useState(null);
  const [workforceTopCardLOS, setWorkforceTopCardLOS] = useState(null);
  const [workforceTopCardShiftUti, setWorkforceTopCardShiftUti] = useState(null);
  const [workforceTopCardPoolUti, setWorkforceTopCardPoolUti] = useState(null);
  const [leaverTopCardLos, setLeaverTopCardLos] = useState(null);
  const [leaverTopCardCounts, setLeaverTopCardCounts] = useState(null);
  const [leaversTopCardPoolUti, setLeaversTopCardPoolUti] = useState(null);

  const [activityBottomCardShiftUtil, setActivityBottomCardShiftUtil] = useState(null);
  const [activityBottomCardHeadCount, setActivityBottomCardHeadCount] = useState(null);
  const [activityBottomCardSpend, setActivityBottomCardSpend] = useState(null);
  const [activityBottomCardAverageHours, setActivityBottomCardAverageHours] = useState(null);

  const [workforceShiftUti, setWorkforceShiftUti] = useState(null);
  const [workforceLOS, setWorkforceLOS] = useState(null);

  const [leaverNSR, setLeaverNSR] = useState(null);
  const [leaverCount, setLeaverCount] = useState(null);
  const [leaverLOS, setLeaverLOS] = useState(null);
  const [regionIdForDashboard, setRegionIdForDashboard] = useState(null);

  const resetData = () => {
    setAgencyRatingData(null);
    setClientRatingData(null);
    setSiteRatingData(null);
    setActivityTopCardData(null);
    setWorkforceTopCardLOS(null);
    setWorkforceTopCardShiftUti(null);
    setWorkforceTopCardPoolUti(null);
    setLeaverTopCardLos(null);
    setLeaverTopCardCounts(null);
    setLeaversTopCardPoolUti(null);
    setActivityBottomCardShiftUtil(null);
    setActivityBottomCardHeadCount(null);
    setActivityBottomCardSpend(null);
    setActivityBottomCardAverageHours(null);
    setWorkforceShiftUti(null);
    setWorkforceLOS(null);
    setLeaverNSR(null);
    setLeaverCount(null);
    setLeaverLOS(null);
    setHeaderData(null);
  };

  const getHeaderDataFunction = async (regionId, startDate, endDate, shiftId) => {
    const header = await getHeaderData(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null,
      shiftId
    );
    if (header && header.ok) {
      setHeaderData(header.data);
    }
  };

  const getSiteRatingDataFunction = async (regionId, shiftId) => {
    const rating = await getSiteRatingData(
      userData.client_id || selected_client_id,
      regionId,
      shiftId
    );
    if (rating && rating.ok) {
      setSiteRatingData(rating.data);
    }
  };

  const getAgencyRatingDataFunction = async () => {
    const rating = await getAgencyRatingData(
      userData.client_id || selected_client_id,
      user_details.agency_id
    );
    if (rating && rating.ok) {
      setAgencyRatingData(rating.data);
    }
  };

  const getClientRatingDataFunction = async () => {
    const rating = await getClientRatingData(userData.client_id || selected_client_id);
    if (rating && rating.ok) {
      setClientRatingData(rating.data);
    }
  };

  const getLeaverAnalysisDataFunction = async (regionId, startDate, endDate, shiftId) => {
    const leaversAnalysis = await getLeaverAnalysisData(
      selected_client_id || userData.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? userData.agency_id : null,
      shiftId
    );
    if (leaversAnalysis && leaversAnalysis.ok) {
      setLeaversData(leaversAnalysis.data);
    }
  };

  const getActivityTopCardDataFunction = async (regionId, startDate, endDate, shiftId) => {
    const activityTopCard = await getActivityTopCardData(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? userData.agency_id
        : null,
      shiftId
    );
    if (activityTopCard && activityTopCard.ok) {
      setActivityTopCardData(activityTopCard.data);
    }
  };

  const getWorkforceTopCardLOSDataFunction = async (regionId, startDate, endDate, shiftId) => {
    const WorkforceTopCard = await getWorkforceTopCardLOSData(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? userData.agency_id
        : null,
      shiftId
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardLOS(WorkforceTopCard.data);
    }
  };

  const getWorkforceTopCardShiftUtiDataFunction = async (regionId, startDate, endDate, shiftId) => {
    const WorkforceTopCard = await getWorkforceTopCardShiftUtiData(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? userData.agency_id
        : null,
      shiftId
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardShiftUti(WorkforceTopCard.data);
    }
  };

  const getWorkforceTopCardPoolUtiDataFunction = async (regionId, startDate, endDate, shiftId) => {
    const WorkforceTopCard = await getWorkforceTopCardPoolUtiData(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? userData.agency_id
        : null,
      shiftId
    );
    if (WorkforceTopCard && WorkforceTopCard.ok) {
      setWorkforceTopCardPoolUti(WorkforceTopCard.data);
    }
  };

  const getLeaverTopCardLOSFunction = async (regionId, startDate, endDate, shiftId) => {
    const leaverTopCardData = await getLeaverTopCardLOS(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? userData.agency_id
        : null,
      shiftId
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaverTopCardLos(leaverTopCardData.data);
    }
  };

  const getLeaverTopCardCountsFunction = async (regionId, startDate, endDate, shiftId) => {
    const leaverTopCardData = await getLeaverTopCardCounts(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? userData.agency_id
        : null,
      shiftId
    );
    if (leaverTopCardData && leaverTopCardData.ok) {
      setLeaverTopCardCounts(leaverTopCardData.data);
    }
  };

  const getActivityBottomCardShiftUtiFunction = async (regionId, startDate, endDate, shiftId) => {
    const bottomDeckData = await getActivityBottomCardShiftUti(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      shiftId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardShiftUtil(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardHeadCountFunction = async (regionId, startDate, endDate, shiftId) => {
    const bottomDeckData = await getActivityBottomCardHeadCount(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      shiftId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardHeadCount(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardSpendFunction = async (regionId, startDate, endDate, shiftId) => {
    const bottomDeckData = await getActivityBottomCardSpend(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      shiftId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardSpend(bottomDeckData?.data);
    }
  };

  const getActivityBottomCardAverageHoursFunction = async (
    regionId,
    startDate,
    endDate,
    shiftId
  ) => {
    const bottomDeckData = await getActivityBottomCardAverageHours(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      shiftId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setActivityBottomCardAverageHours(bottomDeckData?.data);
    }
  };

  const getWorkforceShiftUtiFunction = async (regionId, startDate, endDate, shiftId) => {
    const bottomDeckData = await getWorkforceShiftUti(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      shiftId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setWorkforceShiftUti(bottomDeckData?.data);
    }
  };

  const getWorkforceLOSFunction = async (regionId, startDate, endDate, shiftId) => {
    const bottomDeckData = await getWorkforceLOS(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      shiftId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setWorkforceLOS(bottomDeckData?.data);
    }
  };

  const getLeaverNSRFunction = async (regionId, startDate, endDate, shiftId) => {
    const bottomDeckData = await getLeaverNSR(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      shiftId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverNSR(bottomDeckData?.data);
    }
  };

  const getLeaverCountFunction = async (regionId, startDate, endDate, shiftId) => {
    const bottomDeckData = await getLeaverCount(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      shiftId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverCount(bottomDeckData?.data);
    }
  };

  const getLeaverLOSFunction = async (regionId, startDate, endDate, shiftId) => {
    const bottomDeckData = await getLeaverLOS(
      selected_client_id || userData.client_id || user_details.client_id,
      regionId,
      startDate,
      endDate,
      shiftId
    );
    if (bottomDeckData && bottomDeckData.ok) {
      setLeaverLOS(bottomDeckData?.data);
    }
  };

  const getChartData = (siteId, startDate, endDate, shiftId) => {
    if (isDashboardBottomCardVisible(selected_start_date, selected_end_date)) {
      if (is_restricted && user_details.user_type_id === USER_TYPE.AGENCY_ADMIN) {
        Promise.all([
          getHeaderDataFunction(siteId, startDate, endDate, shiftId),
          getSiteRatingDataFunction(siteId, shiftId),
          // getAgencyRatingDataFunction(),
          // getClientRatingDataFunction(),
          getLeaverAnalysisDataFunction(siteId, startDate, endDate, shiftId)
        ]);
      } else {
        Promise.all([
          getHeaderDataFunction(siteId, startDate, endDate, shiftId),
          getSiteRatingDataFunction(siteId, shiftId),
          // getAgencyRatingDataFunction(),
          // getClientRatingDataFunction(),
          getLeaverAnalysisDataFunction(siteId, startDate, endDate, shiftId),
          getActivityTopCardDataFunction(siteId, startDate, endDate, shiftId),
          getWorkforceTopCardLOSDataFunction(siteId, startDate, endDate, shiftId),
          getWorkforceTopCardShiftUtiDataFunction(siteId, startDate, endDate, shiftId),
          getWorkforceTopCardPoolUtiDataFunction(siteId, startDate, endDate, shiftId),
          getLeaverTopCardLOSFunction(siteId, startDate, endDate, shiftId),
          getActivityBottomCardShiftUtiFunction(siteId, startDate, endDate, shiftId),
          getActivityBottomCardHeadCountFunction(siteId, startDate, endDate, shiftId),
          getActivityBottomCardSpendFunction(siteId, startDate, endDate, shiftId),
          getActivityBottomCardAverageHoursFunction(siteId, startDate, endDate, shiftId),
          getWorkforceShiftUtiFunction(siteId, startDate, endDate, shiftId),
          getWorkforceLOSFunction(siteId, startDate, endDate, shiftId),
          getLeaverNSRFunction(siteId, startDate, endDate, shiftId),
          getLeaverCountFunction(siteId, startDate, endDate, shiftId),
          getLeaverLOSFunction(siteId, startDate, endDate, shiftId),
          getLeaverTopCardCountsFunction(siteId, startDate, endDate, shiftId)
        ]);
      }
    } else {
      Promise.all([
        getSiteRatingDataFunction(siteId, shiftId),
        // getAgencyRatingDataFunction(),
        // getClientRatingDataFunction(),
        getActivityTopCardDataFunction(siteId, startDate, endDate, shiftId),
        getWorkforceTopCardLOSDataFunction(siteId, startDate, endDate, shiftId),
        getWorkforceTopCardShiftUtiDataFunction(siteId, startDate, endDate, shiftId),
        getWorkforceTopCardPoolUtiDataFunction(siteId, startDate, endDate, shiftId),
        getLeaverTopCardLOSFunction(siteId, startDate, endDate, shiftId),
        getLeaverTopCardCountsFunction(siteId, startDate, endDate, shiftId)
      ]);
    }
  };

  useEffect(() => {
    getAgencyRatingDataFunction();
    getClientRatingDataFunction();
  }, []);

  const deleteEntry = (regionId, region, site) => {
    setSelectedRegionSiteList((prevList) => {
      const newList = [...prevList];
      const index = newList.findIndex((item) => Object.keys(item)[0] === regionId);

      if (index !== -1) {
        const entry = newList[index][regionId];
        entry.sites = entry.sites.filter((s) => s !== site);

        if (entry.sites.length === 0) {
          newList.splice(index, 1);
        } else {
          newList[index][regionId] = entry;
        }
        return newList;
      }
      return prevList;
    });
  };

  const deleteEditEntry = (regionId, siteId) => {
    setAssociations((prevAssociations) => {
      return prevAssociations.filter((assoc) => {
        if (assoc.region_id === regionId && assoc.site_id === siteId) {
          // Remove the entry if regionId and siteId match
          return false;
        }
        return true;
      });
    });
  };

  const getShiftList = async () => {
    const clientId = getClientId();
    const siteId = getSiteId();
    const regionId = getRegionId();

    const result = await getShifts(clientId, siteId, regionId);
    if (result.data.ok && result.ok && result.data.count !== 0) {
      setShifts([{ id: '', name: 'All Shifts' }, ...result.data.shifts]);
    }
  };
  useEffect(() => {
    if (shifts.length > 0) {
      isFirstRender.current = true;
      setSelectedShift({ label: shifts[0].name, value: shifts[0].id });
    }
  }, [shifts]);

  useEffect(() => {
    if (Object.keys(selectedShift).length === 0) return;

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (selected_performance_department_id && Object.keys(selectedShift).length > 0) {
      getChartData(
        selected_performance_department_id,
        selected_start_date,
        selected_end_date,
        selectedShift.value ?? null
      );
    }
  }, [selectedShift]);

  useEffect(() => {
    getShiftList();
    if (selected_performance_department_id) {
      resetData();
      setRegionIdForDashboard(selected_performance_department_id);
      getChartData(
        selected_performance_department_id,
        selected_start_date,
        selected_end_date,
        null
      );
    } else {
      resetData();
    }
  }, [selected_performance_department_id, selected_start_date, selected_end_date]);

  return (
    <>
      <Box mb={5}>
        <Card>
          {user_details.user_type_id !== USER_TYPE.SUPER_ADMIN && (
            <Flex>
              <Box width="12rem" mx="15px">
                <DepartmentDropdownHeader refresh={shouldDepartmentsRefresh} />
              </Box>
              <Box width="12rem" mx="15px">
                <Dropdown
                  label="Shift"
                  onSelect={(e) => {
                    setSelectedShift(e);
                  }}
                  options={shifts.map((shift) => {
                    return { label: shift.name, value: shift.id };
                  })}
                  value={selectedShift}
                  placeholder="Select"
                />
              </Box>
            </Flex>
          )}
        </Card>
        {is_restricted && user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? (
          <DashboardRatingCard
            siteRatingData={siteRatingData}
            agencyRatingData={agencyRatingData}
            clientRatingData={clientRatingData}
            headerData={headerData}
            ratingData={ratingData}
            leaversData={leaversData}
            regionId={regionIdForDashboard}
          />
        ) : (
          <DashboardRatingCard
            siteRatingData={siteRatingData}
            agencyRatingData={agencyRatingData}
            clientRatingData={clientRatingData}
            headerData={headerData}
            ratingData={ratingData}
            leaversData={leaversData}
            regionId={regionIdForDashboard}
          />
        )}
        {is_restricted && user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? (
          <Box>
            <Box h="600px" display="flex" justifyContent="center">
              <Center fontSize={35}>Client Has Restricted View</Center>
            </Box>
          </Box>
        ) : (
          <Box mt={10}>
            <>
              <Box>
                <DashboardTopCard
                  activityTopCardData={activityTopCardData}
                  workforceTopCardPoolUti={workforceTopCardPoolUti}
                  workforceTopCardShiftUti={workforceTopCardShiftUti}
                  workforceTopCardLOS={workforceTopCardLOS}
                  leaverTopCardLos={leaverTopCardLos}
                  leaversTopCardPoolUti={leaversTopCardPoolUti}
                  leaverTopCardCounts={leaverTopCardCounts}
                  isDataAvailable={!!(departmentData && departmentData.length > 0)}
                />
              </Box>

              {isCurrentWeek && (
                <Box>
                  <DashboardBottomCard
                    activityBottomCardShiftUtil={activityBottomCardShiftUtil}
                    activityBottomCardSpend={activityBottomCardSpend}
                    activityBottomCardAverageHours={activityBottomCardAverageHours}
                    workforceLOS={workforceLOS}
                    leaverLOS={leaverLOS}
                    leaverCount={leaverCount}
                    leaverNSR={leaverNSR}
                    activityBottomCardHeadCount={activityBottomCardHeadCount}
                    workforceShiftUti={workforceShiftUti}
                    isDataAvailable={!!(departmentData && departmentData.length > 0)}
                  />
                </Box>
              )}
            </>
          </Box>
        )}
      </Box>
      {is_restricted && user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? (
        <Box></Box>
      ) : (
        <StyledDepartmentsContainer>
          {isPageLoader ? <Loader /> : null}
          <Card>
            {permission && permission.includes('create') && (
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => setModalOpen(true)}>
                Add Department
              </Button>
            )}
            <ModalComponent
              isOpen={inModalOpan}
              onClose={() => onModalClose()}
              modalTitle={
                edit ? constants.DEPARTMENT.EDIT_HEADER_TEXT : constants.DEPARTMENT.HEADER_TEXT
              }
              modalContent={
                <Box p="10px">
                  <Flex>
                    <StyledInputWrapper>
                      <InputComponent
                        isRequired
                        lable={constants.NAME}
                        placeholder={constants.NAME}
                        onChange={(e) => setData({ ...data, name: e })}
                        value={data.name}
                        validationObj={validations.name}
                        refresh={refresh}
                        maxLength="50"
                      />
                    </StyledInputWrapper>
                  </Flex>
                  <Flex>
                    <StyledInputWrapper style={{ marginRight: '9px' }}>
                      <Dropdown
                        label="Region"
                        onSelect={(e) => {
                          setSelectedRegion(e);
                        }}
                        options={regionData.map((reg) => {
                          return { label: reg.name, value: reg.id };
                        })}
                        value={selectedRegion}
                        placeholder=""
                      />
                    </StyledInputWrapper>
                    <StyledInputWrapper style={{ width: '60%' }}>
                      <InputComponent
                        lable={constants.COST_CENTRE_CODE}
                        placeholder={constants.COST_CENTRE_CODE}
                        validationObj={validations.cost_centre}
                        onChange={(e) =>
                          setData({
                            ...data,
                            cost_centre: restrictSomeSpecialCharacter(replaceEmojiInInputField(e))
                          })
                        }
                        refresh={refresh}
                        value={data.cost_centre}
                      />
                    </StyledInputWrapper>
                  </Flex>
                  <Flex>
                    <StyledInputWrapper>
                      <Dropdown
                        label="Site"
                        onSelect={(e) => {
                          setSelectedSite(e);
                        }}
                        options={siteData.map((site) => {
                          return { label: site.name, value: site.id };
                        })}
                        value={selectedSite}
                        placeholder=""
                      />
                    </StyledInputWrapper>
                  </Flex>

                  {!edit && (
                    <StyledDiv>
                      {selectedRegionSiteList.length > 0 &&
                        selectedRegionSiteList.map((item) => {
                          const [regionId] = Object.keys(item);
                          const { region, sites } = item[regionId];

                          return (
                            <div key={regionId} style={{ display: 'flex', flexWrap: 'wrap' }}>
                              {sites.map((site) => (
                                <StyledItem
                                  key={`${regionId}-${Object.keys(site)[0]}`}
                                  style={{ fontSize: '12px' }}>
                                  {region} || {Object.values(site)[0]}
                                  <CloseButton
                                    onClick={() => {
                                      deleteEntry(regionId, region, site);
                                    }}
                                    ml={2}
                                    size="sm"
                                  />
                                </StyledItem>
                              ))}
                            </div>
                          );
                        })}
                    </StyledDiv>
                  )}

                  {edit && (
                    <StyledDiv>
                      {association &&
                        association?.map((Adata, index) => {
                          return (
                            <div
                              key={Adata.region_id}
                              style={{ display: 'flex', flexWrap: 'wrap' }}>
                              <StyledItem style={{ fontSize: '12px' }}>
                                {`${Adata.region_name} || ${Adata.site_name}`}
                                <CloseButton
                                  onClick={() => {
                                    deleteEditEntry(Adata.region_id, Adata.site_id);
                                  }}
                                  ml={2}
                                  size="sm"
                                />
                              </StyledItem>
                            </div>
                          );
                        })}
                    </StyledDiv>
                  )}
                </Box>
              }
              negative={
                <Button
                  onClick={() => onModalClose()}
                  bg="main.secondary"
                  mr={3}
                  _hover={{ bg: 'main.secondary' }}
                  color="main.black">
                  {constants.DEPARTMENT.CANCEL_BUTTON_TEXT}
                </Button>
              }
              positive={
                <Button
                  onClick={() => saveRegion()}
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}>
                  {edit
                    ? constants.DEPARTMENT.EDIT_UPDATE_BUTTON_TEXT
                    : constants.DEPARTMENT.SAVE_BUTTON_TEXT}
                </Button>
              }
            />
            <ClientSideDatatable
              pageSize={10}
              initialSortKey="id"
              column={column}
              row={departmentData}
              pagination
              pages={[2, 5, 10, 15]}
            />
          </Card>
        </StyledDepartmentsContainer>
      )}
    </>
  );
};

Department.propTypes = {};

export default Department;
