/* eslint-disable no-use-before-define */
/* eslint-disable import/named */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-cond-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Button, Flex, Image, Text, useToast } from '@chakra-ui/react';
import constants, {
  ADD_CLIENT_VALIDATIONS,
  ADD_USER_VALIDATIONS,
  CUSTOM_TOAST_DURATION,
  PAGE_TITLE,
  PATH,
  TOAST_SETTINGS,
  USER_TYPE
} from '../../constants';
import {
  addClient,
  getClientAdminUser,
  getClients,
  updateClient
} from '../../redux/action/client.action';
import ServerSideDatatable from '../../components/ServerSideDatatable';
import AddClient from '../../components/Forms/AddClients';
import ModalComponent from '../../components/Modal';
import { deepClone, validateForm, validatePostCode } from '../../utils/helper';
import { addUser, blockWorkerInvite, reInviteAdminUser } from '../../redux/action/user.action';
import Card from '../../components/Card';
import StyledClientsContainer from './Client.styled';
import Loader from '../../components/Loader';
import { getModulePermissions } from '../../utils/hooks';
import UserDetails from '../../components/Forms/UserDetails';
import MultiUser from '../../components/Forms/MultiUser';
import AddIcon from '../../assets/images/add.png';
import TrainingRuleForm from '../../components/TrainingRuleForm';
import FinancialRuleList from './components/FinancialRule';

function useAsyncState(initialValue) {
  const [value, setValue] = useState(initialValue);
  const setter = (x) =>
    new Promise((resolve) => {
      setValue(x);
      resolve(x);
    });
  return [value, setter];
}

const Client = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.CLIENT;
  }, []);

  const defaultAdminData = {
    name: '',
    email: '',
    label: '',
    country_code: '+44',
    mobile: ''
  };
  const toast = useToast();
  const permission = getModulePermissions(PATH.CLIENT.CODE);
  const [refresh, setRefresh] = useState(false);
  const [inModalOpan, setModalOpen] = useState(false);
  const [isAdminModal, setAdminModalOpen] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [isClientEdit, setIsClientEdit] = useState(false);
  const [reload, setReload] = useState(0);
  const [sector, setSectorData] = useState({});
  const [country, setCountry] = useState({});
  const [day, setDay] = useState({});
  const [booking, setBooking] = useState({});
  const [clientData, setClientData] = useState({
    name: '',
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    city: '',
    country: '',
    sectorId: '',
    postCode: '',
    weekday_start: '',
    booking_format: '',
    worker_invite_email: false,
    workerPerformance: false,
    workerTraining: false,
    rateCardLookup: false,
    tna_ftp: false,
    tna_cron_expression: '',
    worker_ftp: false,
    worker_cron_expression: '',
    remote_directory: '',
    notification_email: '',
    ftp_host: '',
    ftp_username: '',
    ftp_password: ''
  });
  const [adminData, setAdminData] = useAsyncState([defaultAdminData]);
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [validations, setValidations] = useState(deepClone(ADD_CLIENT_VALIDATIONS));
  const [, setUserValidations] = useState(ADD_USER_VALIDATIONS);
  const [addClick, setAddClick] = useState(false);
  const [saveClick, setSaveClick] = useState(false);
  const [isTrainingRuleModalOpen, setIsTrainingRuleModalOpen] = useState(false);
  const [isFinancialRuleModalOpen, setIsFinancialRuleModalOpen] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedClientWeekDay, setSelectedClientWeekDay] = useState(null);

  const openTrainingRuleModal = (clientIdModal) => {
    setSelectedClientId(clientIdModal);
    setIsTrainingRuleModalOpen(true);
  };

  const openFinancialRuleModal = (clientIdModal, weekDayStart) => {
    setSelectedClientId(clientIdModal);
    setSelectedClientWeekDay(weekDayStart);
    setIsFinancialRuleModalOpen(true);
  };

  const setCountryData = (e) => {
    setCountry(e);
    setClientData({ ...clientData, country: e.value });
  };
  const setDayData = (e) => {
    setDay(e);
    setClientData({ ...clientData, weekday_start: e.value });
  };

  const setBookingData = (e) => {
    setBooking(e);
    setClientData({ ...clientData, booking_format: e.value });
  };

  const onClientModalClose = () => {
    setModalOpen(false);
    setIsClientEdit(false);
    setClientData({
      name: '',
      address_line_1: '',
      address_line_2: '',
      address_line_3: '',
      city: '',
      country: '',
      sectorId: '',
      postCode: '',
      weekday_start: '',
      booking_format: '',
      workerPerformance: false,
      workerTraining: false,
      rateCardLookup: false,
      tna_ftp: false,
      tna_cron_expression: '',
      worker_ftp: false,
      worker_cron_expression: '',
      remote_directory: '',
      notification_email: '',
      ftp_host: '',
      ftp_username: '',
      ftp_password: ''
    });
    setValidations(deepClone(ADD_CLIENT_VALIDATIONS));
    setSectorData(null);
    setCountry(null);
    setDay(null);
    setBooking(null);
  };
  const addClientInExistingCompany = () => {
    setAdminUserDetailsModal(false);
    setAdminModalOpen(true);
  };
  const validateCronSchedule = (cronSchedule) => {
    const cronRegex =
      /^(\d{1,2}|\*)\s(\d{1,2}|\*)\s([1-9]|[12]\d|3[01]|\*)\s(1[0-2]|[1-9]|\*)\s([0-6]|\*)$/;
    return cronRegex.test(cronSchedule);
  };

  const saveClient = async () => {
    // eslint-disable-next-line prefer-const
    let [validationObject, isFormValid] = validateForm(clientData, validations);
    const isValidPostCode = validatePostCode(clientData.postCode);

    // Remove extra spaces and trim the cron expressions before validation
    const trimmedWorkerCron = clientData.worker_cron_expression.replace(/\s+/g, ' ').trim();
    const trimmedTAndACron = clientData.tna_cron_expression.replace(/\s+/g, ' ').trim();

    const isValidWorkerCronSchedule = validateCronSchedule(trimmedWorkerCron);
    const isValidTAndACronSchedule = validateCronSchedule(trimmedTAndACron);

    if (!isValidPostCode) {
      validationObject.postCode.isvalid = false;
      isFormValid = false;
    }

    if (clientData.worker_ftp && !isValidWorkerCronSchedule) {
      isFormValid = false;
    }

    if (clientData.tna_ftp && !isValidTAndACronSchedule) {
      isFormValid = false;
    }

    // Check for required fields when FTP lookups are true
    if (clientData.worker_ftp || clientData.tna_ftp) {
      const requiredFields = [
        { name: 'FTP Host', value: clientData.ftp_host },
        { name: 'FTP Username', value: clientData.ftp_username },
        { name: 'File Path for Upload', value: clientData.remote_directory },
        { name: 'Email for Status Updates', value: clientData.notification_email },
        { name: 'FTP Password', value: clientData.ftp_password }
      ];

      requiredFields.forEach((field) => {
        if (!field.value) {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.ERROR,
            title: `${field.name} is required.`
          });
          isFormValid = false;
        }
      });

      // Validate email format
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (clientData.notification_email && !emailRegex.test(clientData.notification_email)) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.ERROR,
          title: 'Email must be valid'
        });
        isFormValid = false;
      }

      // Added error message for Worker Job Schedule
    }
    if (clientData.worker_ftp) {
      if (!trimmedWorkerCron) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.ERROR,
          title: 'Worker Job Schedule is required when Worker FTP Lookup is enabled.'
        });
        isFormValid = false;
      }
    }

    if (clientData.tna_ftp) {
      if (!trimmedTAndACron) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.ERROR,
          title: 'T&A Job Schedule is required when T&A FTP Lookup is enabled.'
        });
        isFormValid = false;
      }
    }

    if (isFormValid) {
      setClientData({
        ...clientData,
        worker_cron_expression: trimmedWorkerCron,
        tna_cron_expression: trimmedTAndACron
      });
    }

    setValidations(validationObject);
    if (isFormValid) {
      let result = null;
      let blockResult = null;
      if (isClientEdit) {
        const data = {
          ...clientData,
          worker_cron_expression: trimmedWorkerCron,
          tna_cron_expression: trimmedTAndACron
        };
        const wie = data.worker_invite_email;
        delete data.weekday_start;
        delete data.worker_invite_email;
        delete data.booking_format;

        result = await updateClient(clientId, data);
        if (clientData.worker_invite_email) {
          blockResult = await blockWorkerInvite(clientId, { worker_invite_email: wie });
        }
        if ((result && result.ok) || (blockResult && blockResult.ok)) {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
          setIsClientEdit(false);
          onClientModalClose();
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      } else {
        result = await addClient({
          ...clientData,
          worker_cron_expression: trimmedWorkerCron,
          tna_cron_expression: trimmedTAndACron
        });
        if (result && result.ok) {
          setClientId(result.data?.client_id);
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
          setAdminModalOpen(true);
          onClientModalClose();
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      }

      setReload((prev) => prev + 1);
    } else {
      setReload((prev) => prev + 1);
      setRefresh(!refresh);
    }
  };

  const onCloseAdminModal = () => {
    setAdminModalOpen(false);
    setAdminData([
      {
        name: '',
        email: '',
        label: '',
        country_code: '+44',
        mobile: ''
      }
    ]);
    setUserValidations(ADD_USER_VALIDATIONS);
  };
  const saveAdminData = async (data) => {
    setAdminData(data);
    const payloadUsers = data.map((item) => {
      return {
        id: clientId,
        name: item.name,
        email: item.email,
        phone: item.mobile,
        country_code: item.country_code
      };
    });
    const result = await addUser({
      user_type: USER_TYPE.CLIENT_ADMIN,
      users: payloadUsers
    });
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
      onCloseAdminModal();
      setAdminUserDetailsModal(false);
      setReload((prev) => prev + 1);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const editClient = (id, data) => {
    setIsClientEdit(true);
    setCountry({ label: data.country, value: data.country });
    setDay({ label: data.weekday_start, value: data.weekday_start });
    setSectorData({ label: data.sector_name, value: data.sector_id });
    setBooking({ label: data.booking_format, value: data.booking_format });
    const address = JSON.parse(data.address);
    setClientData({
      name: data.client_name,
      address_line_1: address.address_line_1,
      address_line_2: address.address_line_2,
      address_line_3: address.address_line_3,
      city: data.city,
      country: data.country,
      postCode: data.post_code,
      sectorId: data.sector_id,
      weekday_start: data.weekday_start,
      booking_format: data.booking_format,
      worker_invite_email: data.worker_invite_email,
      workerPerformance: data.worker_performance,
      workerTraining: data.worker_training,
      rateCardLookup: data.rate_card_lookup,
      tna_ftp: data.tna_ftp || false,
      worker_ftp: data.worker_ftp || false,
      ftp_host: data.ftp_host || '',
      ftp_username: data.ftp_username || '',
      ftp_password: data.ftp_password || '',
      tna_cron_expression: data.tna_cron_expression || '',
      worker_cron_expression: data.worker_cron_expression || '',
      remote_directory: data.remote_directory || '',
      notification_email: data.notification_email || ''
    });
    setModalOpen(true);
    setClientId(id);
  };

  useEffect(() => {
    setIsPageLoader(true);
  }, []);

  const [column, setColumn] = useState([
    {
      label: 'Name',
      field: 'client_name',
      sort: true
    },
    {
      label: 'City',
      field: 'city',
      sort: true
    },
    {
      label: 'Sector',
      field: 'sector_name',
      sort: true
    },
    {
      label: 'Nationality',
      field: 'country',
      sort: true
    }
  ]);

  const [adminUserData, setAdminUserData] = useState({});
  const [adminUserDetailsModal, setAdminUserDetailsModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState('');

  const viewUserData = async (id, name) => {
    setSelectedClient(name);
    const result = await getClientAdminUser(id);
    setClientId(id);
    setAdminUserDetailsModal(true);
    if (result && result.ok) {
      setAdminUserData(result.data?.users);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const reInviteUser = async (userId) => {
    const result = await reInviteAdminUser(userId);
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
    setReload((prev) => prev + 1);
  };

  useEffect(() => {
    if (permission && permission.includes('edit')) {
      setColumn([
        ...column,
        {
          label: 'Add Training Rules',
          field: 'client_id',
          sort: false,
          customRow: (cell, row) => {
            const ruleCount = row.training_rule_count;
            const ruleText = `${ruleCount} ${Number(ruleCount) <= 1 ? 'Rule' : 'Rules'}`;
            return (
              <Flex align="center">
                <Image
                  mr="10px"
                  cursor="pointer"
                  src={AddIcon}
                  height={6}
                  onClick={() => openTrainingRuleModal(cell)}
                />
                <Text fontSize="lg" fontWeight="bold" color="blue.600">
                  {ruleText}
                </Text>
              </Flex>
            );
          }
        },
        {
          label: 'Financial Rules',
          field: 'client_id',
          sort: false,
          customRow: (cell, row) => {
            const yearlyRuleCount = row.client_yearly_rules_count ?? 0;
            const yearlyRuleText = `${yearlyRuleCount} Manage ${
              +yearlyRuleCount <= 1 ? 'Rule' : 'Rules'
            }`;
            return (
              <Flex align="center">
                <Image
                  mr="10px"
                  cursor="pointer"
                  src={AddIcon}
                  height={6}
                  onClick={() => openFinancialRuleModal(cell, row.weekday_start)}
                />
                <Text fontSize="lg" fontWeight="bold" color="blue.600">
                  {yearlyRuleText}
                </Text>
              </Flex>
            );
          }
        },
        {
          label: 'Action',
          field: 'client_id',
          sort: false,
          customRow: (cell, row) => {
            return (
              <Flex justifyContent="space-evenly" alignItems="center">
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={() => editClient(cell, row)}>
                  {constants.CLIENT.EDIT_BUTTON_TEXT}
                </Button>
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={() => viewUserData(cell, row.client_name)}>
                  {constants.CLIENT.VIEW_USER_BUTTON_TEXT}
                </Button>
              </Flex>
            );
          }
        }
      ]);
    }
  }, [permission]);

  return (
    <StyledClientsContainer>
      {isPageLoader ? <Loader /> : null}
      <Card>
        {permission && permission.includes('create') && (
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            onClick={() => setModalOpen(true)}>
            Add Client
          </Button>
        )}
        <FinancialRuleList
          isOpen={isFinancialRuleModalOpen}
          onClose={() => setIsFinancialRuleModalOpen(false)}
          clientId={selectedClientId}
          clientWeekDay={selectedClientWeekDay}
        />
        <TrainingRuleForm
          tableRefreash={() => setReload((prev) => prev + 1)}
          isOpen={isTrainingRuleModalOpen}
          onClose={() => setIsTrainingRuleModalOpen(false)}
          clientId={selectedClientId}
        />
        <ModalComponent
          isOpen={inModalOpan}
          onClose={onClientModalClose}
          modalTitle={
            isClientEdit ? constants.CLIENT.EDIT_HEADER_TEXT : constants.CLIENT.HEADER_TEXT
          }
          modalContent={
            <AddClient
              setData={setClientData}
              data={clientData}
              validations={validations}
              refresh={refresh}
              sectorValue={sector}
              countryValue={country}
              dayValue={day}
              setCountryData={setCountryData}
              setDayData={setDayData}
              bookingValue={booking}
              setBookingData={setBookingData}
              isClientEdit={isClientEdit}
            />
          }
          negative={
            <Button
              onClick={() => onClientModalClose()}
              bg="main.secondary"
              mr={3}
              _hover={{ bg: 'main.secondary' }}
              color="main.black">
              {constants.CLIENT.CANCEL_BUTTON_TEXT}
            </Button>
          }
          positive={
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => {
                saveClient();
              }}>
              {isClientEdit
                ? constants.CLIENT.EDIT_UPDATE_BUTTON_TEXT
                : constants.CLIENT.SAVE_BUTTON_TEXT}
            </Button>
          }
        />
        <ModalComponent
          isOpen={isAdminModal}
          onClose={onCloseAdminModal}
          modalTitle="Add User"
          modalContent={
            <MultiUser
              adminData={adminData}
              setAdminData={setAdminData}
              refresh={refresh}
              addClick={addClick}
              saveClick={saveClick}
              setSaveClick={setSaveClick}
              setRefresh={setRefresh}
              setAddClick={setAddClick}
              saveAdminData={saveAdminData}
              onCloseAdminModal={onCloseAdminModal}
              setAdminUserDetailsModal={setAdminUserDetailsModal}
              Id={clientId}
              ADMIN={USER_TYPE.CLIENT_ADMIN}
            />
          }
          positive={
            <>
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                mr={3}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => setAddClick(true)}>
                Add
              </Button>
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                disabled={saveClick}
                onClick={() => setSaveClick(true)}>
                Save
              </Button>
            </>
          }
        />
        <ModalComponent
          isOpen={adminUserDetailsModal}
          onClose={() => {
            setAdminUserDetailsModal(false);
            setAdminUserData({});
          }}
          modalTitle={`${selectedClient} - Users`}
          modalContent={<UserDetails data={adminUserData} reInviteUser={reInviteUser} />}
          positive={
            <>
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                mr={3}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => {
                  addClientInExistingCompany();
                }}>
                Add
              </Button>
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => {
                  setAdminUserDetailsModal(false);
                  setAdminUserData({});
                }}>
                Ok
              </Button>
            </>
          }
        />
        <ServerSideDatatable
          pageSize={5}
          initialSortKey="client_name"
          column={column}
          onPaginate={getClients}
          afterPaginateData={() => setIsPageLoader(false)}
          pagination
          pages={[5, 10, 15]}
          dataKey="client_details"
          refresh={reload}
        />
      </Card>
    </StyledClientsContainer>
  );
};

Client.propTypes = {};

export default Client;
