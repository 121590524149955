/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { StyledDivider, StyledModal } from './Modal.styled';

const ModalComponent = function (props) {
  const {
    isOpen,
    onClose,
    modalTitle,
    modalContent,
    negative,
    positive,
    specifySize,
    specifyDivider,
    overflow
  } = props;
  return (
    <StyledModal
      size={specifySize || 'xl'}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent style={{ maxHeight: 'calc(100vh - 20%)' }}>
        <ModalHeader color="main.primary">{modalTitle}</ModalHeader>
        {specifyDivider ? null : <StyledDivider />}
        <ModalCloseButton />
        <ModalBody style={{ overflow }}>{modalContent}</ModalBody>
        {specifyDivider ? null : <StyledDivider />}
        <ModalFooter>
          {negative}
          {positive}
        </ModalFooter>
      </ModalContent>
    </StyledModal>
  );
};

ModalComponent.propTypes = {
  isOpen: PropTypes.bool,
  modalContent: PropTypes.node,
  modalTitle: PropTypes.string,
  negative: PropTypes.node,
  positive: PropTypes.node,
  onClose: PropTypes.func,
  overflow: PropTypes.string
};

ModalComponent.defaultProps = {
  isOpen: false,
  modalContent: <div>hello there</div>,
  modalTitle: 'Title',
  negative: null,
  positive: null,
  onClose: () => {},
  overflow: 'auto'
};

export default ModalComponent;
