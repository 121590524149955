/* eslint-disable radix */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import moment from 'moment';
import { Box, Button, Flex, Image, Text, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import ModalComponent from '../Modal';
import InputComponent from '../Input';
import { StyledUploadFileButton } from './PerformanceModal.styled';
import {
  USER_TYPE,
  TOAST_SETTINGS,
  CUSTOM_TOAST_DURATION,
  CUSTOM_TOAST_DURATION_NULL
} from '../../constants';
import AlertDialogComponent from '../AlertModalComponent';
import DownloadIcon from '../../icons/DownloadIcon';
import { StyledDownloadSample, StyledUploadLabel } from '../../pages/Attendance/Attendance.styled';
import DeleteIcon from '../../assets/images/delete-icon.png';
import SiteDropdown from '../Forms/SiteDropdown';
import { deepClone, filterErrorData, validateForm } from '../../utils/helper';
import {
  deleteWorkerPerformance,
  downloadPerformanceSample,
  downloadWorkerPerformance,
  uploadWorkersPerformance
} from '../../redux/action/performance.action';

const PerformanceModal = function ({
  isWorkerPerformanceModalOpen,
  onCloseWorkerPerformance,
  week,
  clientName,
  site,
  dateRange,
  clientId,
  siteId
}) {
  const { user_details: userDetails } = useSelector((state) => state.authentication);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedSite, setSelectedSite] = useState({ site: '' });
  const [openDeleteAlertModal, setOpenDeleteAlertModal] = useState(false);
  const [siteValidation, setSiteValidation] = useState(
    deepClone({
      site: {
        required: true,
        errorMessage: '*Please Select Valid Site',
        isvalid: true
      }
    })
  );
  const [refresh, setRefresh] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (isWorkerPerformanceModalOpen) {
      // Fetch file or other initialization logic
    } else {
      // Reset state when modal is closed
      setUploadedFile(null);
      setSelectedSite('');
      setSiteValidation((prevState) => ({
        ...prevState,
        site: { ...prevState.site, isvalid: true }
      }));
    }
  }, [isWorkerPerformanceModalOpen]);

  const setSite = (e) => {
    setSelectedSite((prev) => ({ ...prev, site: e }));
    setSiteValidation((prevState) => ({
      ...prevState,
      site: { ...prevState.site, isvalid: true }
    }));
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
    }
  };

  const handleConfirm = async () => {
    if (
      userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
      userDetails.user_type_id === USER_TYPE.REGION_ADMIN
    ) {
      const [validationObject, isFormValid] = validateForm(selectedSite, siteValidation);
      setSiteValidation(validationObject);
      if (!isFormValid) {
        setRefresh((prev) => !prev);
        return;
      }
    }

    if (!uploadedFile) {
      toast({
        ...TOAST_SETTINGS.ERROR,
        status: TOAST_SETTINGS.ERROR,
        title: 'Please first select a file.'
      });
      return;
    }

    const startDate = moment(
      `${dateRange[0].year}/${dateRange[0].month}/${dateRange[0].day}`,
      'YYYY-MM-DD'
    );
    const endDate = moment(
      `${dateRange[1].year}/${dateRange[1].month}/${dateRange[1].day}`,
      'YYYY-MM-DD'
    );

    // Upload file via API call
    try {
      const params = {
        clientId,
        siteId:
          userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
          userDetails.user_type_id === USER_TYPE.REGION_ADMIN
            ? selectedSite?.site?.value
            : siteId,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        week,
        workerPerformance: uploadedFile
      };

      const response = await uploadWorkersPerformance(
        params.clientId,
        params.siteId,
        params.startDate,
        params.endDate,
        params.week,
        params.workerPerformance
      );

      if (response && response.ok) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: response.message
        });
        onCloseWorkerPerformance();
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION_NULL.duration,
          status: TOAST_SETTINGS.ERROR,
          title: filterErrorData(response.error)
        });
        // Handle error response
      }
    } catch (errorApi) {
      toast({
        ...TOAST_SETTINGS.ERROR,
        title: 'An error occurred during file upload.'
      });
    }
  };

  const downloadWorkerPerformanceApiCall = async () => {
    if (
      userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
      userDetails.user_type_id === USER_TYPE.REGION_ADMIN
    ) {
      const [validationObject, isFormValid] = validateForm(selectedSite, siteValidation);
      setSiteValidation(validationObject);
      if (!isFormValid) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION_NULL.duration,
          status: TOAST_SETTINGS.ERROR,
          title: 'Please select site'
        });
        setRefresh((prev) => !prev);
        return;
      }
    }

    const startDate = moment(
      `${dateRange[0].year}/${dateRange[0].month}/${dateRange[0].day}`,
      'YYYY-MM-DD'
    );
    const endDate = moment(
      `${dateRange[1].year}/${dateRange[1].month}/${dateRange[1].day}`,
      'YYYY-MM-DD'
    );

    // Download worker performance via API call
    try {
      const params = {
        clientId,
        siteId:
          userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
          // userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
          userDetails.user_type_id === USER_TYPE.REGION_ADMIN
            ? selectedSite?.site?.value
            : siteId,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        week
      };

      const response = await downloadWorkerPerformance(
        params.clientId,
        params.siteId,
        params.startDate,
        params.endDate,
        params.week
      );

      if (response && response.ok) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.setAttribute('href', response.data.files_to_download[0].resource_url);
        a.setAttribute('download', 'Worker-Performance');
        a.click();
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION_NULL.duration,
          status: TOAST_SETTINGS.ERROR,
          title: filterErrorData(response.error)
        });
      }
    } catch (errorApi) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION_NULL.duration,
        status: TOAST_SETTINGS.ERROR,
        title: 'An error occurred during the download worker performance operation.'
      });
    }
  };

  const deleteWorkerPerformanceApiCall = async () => {
    if (
      userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
      userDetails.user_type_id === USER_TYPE.REGION_ADMIN
      // || userDetails.user_type_id === USER_TYPE.SITE_ADMIN
    ) {
      const [validationObject, isFormValid] = validateForm(selectedSite, siteValidation);
      setSiteValidation(validationObject);
      if (!isFormValid) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION_NULL.duration,
          status: TOAST_SETTINGS.ERROR,
          title: 'Please select site'
        });
        setRefresh((prev) => !prev);
        return;
      }
    }

    const startDate = moment(
      `${dateRange[0].year}/${dateRange[0].month}/${dateRange[0].day}`,
      'YYYY-MM-DD'
    );
    const endDate = moment(
      `${dateRange[1].year}/${dateRange[1].month}/${dateRange[1].day}`,
      'YYYY-MM-DD'
    );

    // Delete worker performance via API call
    try {
      const params = {
        clientId,
        siteId:
          userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
          userDetails.user_type_id === USER_TYPE.REGION_ADMIN
            ? selectedSite?.site?.value
            : siteId,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD')
      };

      const response = await deleteWorkerPerformance(
        params.clientId,
        params.siteId,
        params.startDate,
        params.endDate
      );

      if (response && response.ok) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: response.message
        });
        setOpenDeleteAlertModal(false);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION_NULL.duration,
          status: TOAST_SETTINGS.ERROR,
          title: filterErrorData(response.error)
        });
      }
    } catch (errorApi) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION_NULL.duration,
        status: TOAST_SETTINGS.ERROR,
        title: 'An error occurred during the delete worker performance operation.'
      });
    }
  };

  const downloadBtnClickHandler = async () => {
    const result = await downloadPerformanceSample();
    if (result.ok) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.setAttribute('href', result.data.resource_url);
      a.setAttribute('download', 'Worker-Performance-Report-Sample');
      a.click();
    }
  };

  const getDownloadSampleBtn = () => (
    <StyledDownloadSample title="Download sample" onClick={downloadBtnClickHandler}>
      <DownloadIcon />
    </StyledDownloadSample>
  );

  return (
    <div>
      <ModalComponent
        isOpen={isWorkerPerformanceModalOpen}
        onClose={onCloseWorkerPerformance}
        modalTitle="Add Performance"
        modalContent={
          <Box>
            <Flex>
              <Box w="240px" mt={2}>
                <Text>Choose Week</Text>
              </Box>
              <Box flex="1">
                <InputComponent
                  lable=""
                  placeholder="Choose Week"
                  value={week}
                  validationObj={{ isvalid: true }}
                  m={0}
                  disabled
                />
              </Box>
            </Flex>
            <Flex>
              <Box w="240px" mt={2}>
                <Text>Client Name</Text>
              </Box>
              <Box flex="1">
                <InputComponent
                  lable=""
                  placeholder="Client Name"
                  value={clientName}
                  validationObj={{ isvalid: true }}
                  disabled
                />
              </Box>
            </Flex>
            <Flex>
              <Box w="240px" mt={2}>
                <Text>Site</Text>
              </Box>
              {userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
              userDetails.user_type_id === USER_TYPE.REGION_ADMIN ? (
                <Box flex="1">
                  <SiteDropdown
                    setSite={setSite}
                    site={selectedSite.site}
                    validationObj={siteValidation.site}
                    label=""
                    refresh={refresh}
                  />
                </Box>
              ) : (
                <Box flex="1">
                  <InputComponent
                    lable=""
                    placeholder="Site"
                    value={site}
                    validationObj={{ isvalid: true }}
                    disabled
                  />
                </Box>
              )}
            </Flex>

            <Flex
              style={{
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
              mb={4}>
              <Box w="240px">
                <StyledUploadLabel>Sample File {getDownloadSampleBtn()}</StyledUploadLabel>
              </Box>
              <Box mr={8}>
                <StyledUploadFileButton bg="main.primary" for="clock-report" disabled={false}>
                  Add File
                </StyledUploadFileButton>
                <input
                  type="file"
                  id="clock-report"
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </Box>
              <Box display="flex">
                <Button
                  title="Download Uploaded Worker Performance Sheet"
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  padding="6px"
                  size="sm"
                  mr="8px"
                  onClick={() => {
                    downloadWorkerPerformanceApiCall();
                  }}>
                  <DownloadIcon />
                </Button>
                {(userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.REGION_ADMIN) && (
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    padding="6px"
                    size="sm"
                    onClick={() => {
                      setOpenDeleteAlertModal(true);
                    }}>
                    <Image src={DeleteIcon} height={4} />
                  </Button>
                )}
              </Box>
            </Flex>
            {uploadedFile ? (
              <Flex display="flex" alignItems="center" mb={4}>
                <Text mr={4}>{uploadedFile.name}</Text>
              </Flex>
            ) : null}
          </Box>
        }
        positive={
          <Button
            onClick={handleConfirm}
            bg="main.primary"
            color="main.white"
            _hover={{ bg: 'main.semiPrimary' }}>
            Confirm
          </Button>
        }
      />
      <AlertDialogComponent
        isOpen={openDeleteAlertModal}
        onClose={() => {
          setOpenDeleteAlertModal(false);
        }}
        title="Delete Performance"
        body={
          <Box>
            Deleting worker performance will also{' '}
            <span style={{ color: 'red' }}>
              delete the related payroll upload and impact the calculations for training related
              performance credits{' '}
            </span>
            for the chosen week.
            <br /> <br />
            Are you sure you want to proceed?
          </Box>
        }
        negative={
          <Button
            bg="main.secondary"
            mr={3}
            _hover={{ bg: 'main.secondary' }}
            color="main.black"
            onClick={() => {
              setOpenDeleteAlertModal(false);
            }}>
            Cancel
          </Button>
        }
        positive={
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            onClick={() => {
              deleteWorkerPerformanceApiCall();
            }}>
            {' '}
            Confirm
          </Button>
        }
      />
    </div>
  );
};

export default PerformanceModal;
