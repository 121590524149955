/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/function-component-definition */
import { Box, Flex, Grid, GridItem, Heading, Image, Text, Tooltip } from '@chakra-ui/react';
import greenArrowUp from '../../../assets/images/greenArrowUp.png';
import redArrowDown from '../../../assets/images/redArrowDown.png';

import CircularChart from '../../../components/Charts/CircularChart';
import ColumnChart from '../../../components/Charts/ColumnChart';
import { DASHBOARD_TEXT_COLOR, NEW_CHART_COLOR } from '../../../constants';
import { colors } from '../../../theme';
import { addEuroSymbol, numberFormatter } from '../../../utils/helper';

export default function SiteViewCard({ title, data }) {
  let shiftUtilisation = JSON.parse(data.shift_utilisation);
  shiftUtilisation = Object.values(shiftUtilisation);
  const shiftUtilisationTotal = shiftUtilisation.reduce((a, b) => a + b, 0);

  let performance = +data.performance;
  performance = performance.toFixed(1) < 10 ? `0${performance.toFixed(1)}` : performance.toFixed(1);

  const calShiftFulfillment = (
    fulfilled_total,
    fulfilled_supervisors_total,
    requested_total,
    requested_supervisors_total
  ) => {
    const fulfillment =
      ((fulfilled_total + fulfilled_supervisors_total) /
        (requested_total + requested_supervisors_total)) *
      100;
    if (Number.isNaN(fulfillment) && fulfillment > 100) return 100;
    return Number.isNaN(fulfillment) ? 0 : fulfillment.toFixed(2);
  };

  const calculatePoolUtilisation = (total_workers, active_workers) => {
    const pool_utilisation = (+active_workers / +total_workers) * 100;
    if (Number.isNaN(pool_utilisation) && pool_utilisation > 100) return 100;
    return Number.isNaN(pool_utilisation) ? 0 : pool_utilisation.toFixed(2);
  };

  const showIndicator = (key, currentData, pastData) => {
    if (!key) {
      if (currentData !== pastData) {
        return (
          <Text margin="auto">
            <Image w={6} me={2} src={currentData > pastData ? greenArrowUp : redArrowDown} />
          </Text>
        );
      }
    }
    if (data[key] !== data[`past_${key}`]) {
      return (
        <Text margin="auto">
          <Image w={6} me={2} src={data[key] > data[`past_${key}`] ? greenArrowUp : redArrowDown} />
        </Text>
      );
    }
    return null;
  };

  return (
    <Box borderRadius="md" boxShadow="md" p={4} w="100%" bg="white" overflow="hidden">
      <Heading size="md" textAlign="center" mb={6}>
        {title}
      </Heading>
      <Grid
        h="22.5rem"
        templateRows="repeat(3, 1fr)"
        templateColumns={{
          base: '1fr',
          sm: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
          xl: 'repeat(4, 1fr)'
        }}
        gap={1}
        overflowY={{ lg: 'scroll', xl: 'hidden' }}
        overflowX="hidden">
        {/* Shift Fullfilment */}
        <GridItem>
          <Flex direction="column" justifyContent="center" alignItems="center">
            <Flex justifyContent="space-around" alignItems="center">
              <Tooltip textAlign="center" label="Requested vs FulFillment %" borderRadius="md">
                <Text fontWeight="medium" fontSize={{ base: 'xs', md: 'md' }}>
                  Shift Fulfilment
                </Text>
              </Tooltip>
              {showIndicator(
                null,
                calShiftFulfillment(
                  data.fulfilled_total,
                  data.fulfilled_supervisors_total,
                  data.requested_total,
                  data.requested_supervisors_total
                ),
                calShiftFulfillment(
                  data.past_fulfilled_total,
                  data.past_fulfilled_supervisors_total,
                  data.past_requested_total,
                  data.past_requested_supervisors_total
                )
              )}
            </Flex>
            <CircularChart
              fontSize="md"
              value={calShiftFulfillment(
                data.fulfilled_total,
                data.fulfilled_supervisors_total,
                data.requested_total,
                data.requested_supervisors_total
              )}
              displayValue={calShiftFulfillment(
                data.fulfilled_total,
                data.fulfilled_supervisors_total,
                data.requested_total,
                data.requested_supervisors_total
              )}
              maxW="6rem"
              valueType="%"
              label="Requested"
              count={+(data.requested_total + data.requested_supervisors_total).toFixed(2)}
              extraLabel="Fulfilled"
              extraCount={+(data.fulfilled_total + data.fulfilled_supervisors_total).toFixed(2)}
              max={100}
            />
          </Flex>
        </GridItem>
        {/* Ave Hours */}
        <GridItem>
          <Flex direction="column" justifyContent="center" alignItems="center">
            <Flex justifyContent="space-around" alignItems="center">
              <Tooltip textAlign="center" label="Average of all worker's hours" borderRadius="md">
                <Text fontWeight="medium" fontSize={{ base: 'xs', md: 'md' }}>
                  Ave Hours
                </Text>
              </Tooltip>
            </Flex>
            <Flex mt={6}>
              {showIndicator('ave_hours')}
              <Text margin="auto" fontSize="3xl" fontWeight="semibold">
                {data.ave_hours}
              </Text>
            </Flex>
          </Flex>
        </GridItem>
        {/* Pool */}
        <GridItem>
          <Flex direction="column" justifyContent="center" alignItems="center">
            <Tooltip textAlign="center" label="Activer workers vs Declared pool" borderRadius="md">
              <Text fontWeight="medium" fontSize={{ base: 'xs', md: 'md' }}>
                Pool Utilisation
              </Text>
            </Tooltip>
            <CircularChart
              fontSize="md"
              value={calculatePoolUtilisation(data.pool_total_workers, data.pool_active_workers)}
              displayValue={calculatePoolUtilisation(
                data.pool_total_workers,
                data.pool_active_workers
              )}
              max={100}
              circleRatio={0.5}
              styles={{
                pathColor: NEW_CHART_COLOR.BLUE,
                trailColor: colors.main.blueBackground,
                rotation: -1 / 4
              }}
              maxW="8rem"
              valueType="%"
              label="Active Workers"
              extraLabel="Total Workers"
              count={data.pool_active_workers}
              extraCount={data.pool_total_workers}
            />
          </Flex>
        </GridItem>
        {/* Leavers */}
        <GridItem>
          <Flex h={100} direction="column" justifyContent="center" alignItems="center">
            <Tooltip textAlign="center" label="No. of confirmed leavers" borderRadius="md">
              <Text fontWeight="medium" fontSize={{ base: 'xs', md: 'md' }}>
                Leavers
              </Text>
            </Tooltip>
            <Flex as="div" h={100}>
              <Text
                margin="auto"
                fontSize="5xl"
                fontWeight="semibold"
                color={DASHBOARD_TEXT_COLOR.TEXT_COLOR}>
                {data.leavers_count ?? 0}
              </Text>
            </Flex>
          </Flex>
        </GridItem>
        {/* Spend */}
        <GridItem>
          <Flex h={100} direction="column" justifyContent="center" alignItems="center">
            <Flex justifyContent="space-around" alignItems="center">
              <Tooltip textAlign="center" label="Sum of standard spend" borderRadius="md">
                <Text fontWeight="medium" fontSize={{ base: 'xs', md: 'md' }}>
                  Spend
                </Text>
              </Tooltip>
              {showIndicator('spend')}
            </Flex>
            <Text fontSize="2xl" fontWeight="bold" mt={2}>
              {addEuroSymbol(+data.spend + +data.wtr_cost)}
            </Text>
          </Flex>
        </GridItem>
        {/* Hours */}
        <GridItem>
          <Flex h={100} direction="column" justifyContent="center" alignItems="center">
            <Flex justifyContent="space-around" alignItems="center">
              <Tooltip textAlign="center" label="Sum of standard hours" borderRadius="md">
                <Text fontWeight="medium" fontSize={{ base: 'xs', md: 'md' }}>
                  Hours
                </Text>
              </Tooltip>
              {showIndicator('hours')}
            </Flex>
            <Text fontSize="2xl" fontWeight="bold" mt={2}>
              {data.hours}
            </Text>
          </Flex>
        </GridItem>
        {/* Performance */}
        <GridItem rowSpan={2}>
          <Flex direction="column" justifyContent="center" alignItems="center">
            <Tooltip textAlign="center" label="Average performance" borderRadius="md">
              <Text fontWeight="medium" fontSize={{ base: 'xs', md: 'md' }}>
                Performance
              </Text>
            </Tooltip>
            <Flex
              justifyContent="center"
              alignItems="center"
              mt="8"
              h={100}
              w={100}
              border="solid"
              borderColor={colors.main.blueBackground}
              borderRadius="100%"
              fontSize="3xl"
              fontWeight="extrabold"
              color={colors.main.primary}>
              {performance}
            </Flex>
          </Flex>
        </GridItem>
        {/* Shift Utilisation */}
        <GridItem rowSpan={2}>
          <Flex direction="column" justifyContent="center" alignItems="center">
            <Tooltip textAlign="center" label="Total number of shifts per worker" borderRadius="md">
              <Text fontWeight="medium" fontSize={{ base: 'xs', md: 'md' }}>
                Shift Utilisation
              </Text>
            </Tooltip>
            <ColumnChart
              colors={[colors.main.primary]}
              series={[
                {
                  data: shiftUtilisation
                }
              ]}
              categories={[1, 2, 3, 4, 5, 6, 7]}
              header=""
              customColor={false}
              categoriesVisible
              percentage={shiftUtilisation.map((d) => {
                return shiftUtilisationTotal !== 0
                  ? numberFormatter((d / shiftUtilisationTotal) * 100)
                  : numberFormatter(0);
              })}
            />
          </Flex>
        </GridItem>
        {/* OT Spend */}
        <GridItem>
          <Flex h={100} direction="column" justifyContent="center" alignItems="center">
            <Tooltip textAlign="center" label="Overtime spend %" borderRadius="md">
              <Text fontWeight="medium" fontSize={{ base: 'xs', md: 'md' }}>
                OT Spend
              </Text>
            </Tooltip>
            <Text fontSize="2xl" fontWeight="bold">
              {data.ot_spend_per}%
            </Text>
          </Flex>
        </GridItem>
        {/* OT Hours */}
        <GridItem>
          <Flex h={100} direction="column" justifyContent="center" alignItems="center">
            <Tooltip textAlign="center" label="Overtime hours %" borderRadius="md">
              <Text fontWeight="medium" fontSize={{ base: 'xs', md: 'md' }}>
                OT Hours
              </Text>
            </Tooltip>
            <Text fontSize="2xl" fontWeight="bold">
              {data.ot_hour_per}%
            </Text>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
}
