/* eslint-disable react/function-component-definition */
/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  VStack,
  Heading,
  Text,
  Grid,
  GridItem,
  Button,
  useToast,
  Flex
} from '@chakra-ui/react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import Loader from '../../../components/Loader';
import { StyledBox } from '../PerformanceDashboard.styled';
import {
  getNewStarterPerformance,
  getPerformanceShiftsBlocks,
  getPerformanceByTenure
} from '../../../redux/action/performanceGraph.action';
import { CUSTOM_TOAST_DURATION, TOAST_SETTINGS, USER_TYPE } from '../../../constants';
import Card from '../../../components/Card';

const NewStarterPerformance = () => {
  const debounceTimeout = useRef(null);
  const [starterData, setStarterData] = useState(null);
  const [tenureData, setTenureData] = useState(null);
  const [shiftData, setShiftData] = useState(
    Array.from({ length: 7 }, (_, i) => ({
      shifts: i + 1,
      value: 0,
      count: 0
    }))
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useToast();
  const { userData, user_details } = useSelector((state) => state.authentication);
  const {
    selected_site_id,
    selected_site_name,
    selected_client_id,
    selected_performance_region_id,
    selected_performance_site_id,
    selected_performance_shift_id,
    selected_performance_department_id,
    selected_performance_agency_id,
    selected_performance_region_name,
    selected_performance_site_name,
    selected_performance_shift_name,
    selected_performance_department_name
  } = useSelector((state) => state.agency);
  const { startDate, endDate } = useSelector((state) => state.weekRange);
  const getClientId = () => {
    switch (user_details.user_type_id) {
      case USER_TYPE.CLIENT_ADMIN:
        return userData.client_id;
      case USER_TYPE.AGENCY_ADMIN:
      case USER_TYPE.AGENCY_REGION_ADMIN:
      case USER_TYPE.AGENCY_SITE_ADMIN:
        return selected_client_id || userData.client_id;
      default:
        return selected_client_id || userData.client_id;
    }
  };
  const getSiteId = () => {
    switch (user_details.user_type_id) {
      case USER_TYPE.AGENCY_ADMIN:
        return selected_performance_site_id;
      case USER_TYPE.AGENCY_SITE_ADMIN:
        return user_details.site_id;
      case USER_TYPE.AGENCY_REGION_ADMIN:
        return selected_performance_site_id;
      case USER_TYPE.CLIENT_ADMIN:
        return selected_performance_site_id;
      case USER_TYPE.SITE_ADMIN:
        return user_details.site_id;
      default:
        return selected_performance_site_id || userData.site_id || selected_site_id || '';
    }
  };

  // Helper function to determine region ID based on user type
  const getRegionId = () => {
    switch (user_details.user_type_id) {
      case USER_TYPE.SUPER_ADMIN:
        return selected_performance_region_id;
      case USER_TYPE.CLIENT_ADMIN:
        return selected_performance_region_id;
      case USER_TYPE.AGENCY_ADMIN:
        return selected_performance_region_id;
      case USER_TYPE.AGENCY_REGION_ADMIN:
        return user_details.region_id;
      case USER_TYPE.AGENCY_SITE_ADMIN:
        return '';
      case USER_TYPE.SITE_ADMIN:
        return '';
      default:
        return selected_performance_region_id || userData.region_id;
    }
  };

  // Fetch and set shifts performance data
  const fetchShiftPerformanceData = async () => {
    try {
      const shiftsResult = await getPerformanceShiftsBlocks(
        getClientId(),
        startDate,
        endDate,
        selected_performance_agency_id,
        getRegionId(),
        getSiteId(),
        selected_performance_shift_id,
        selected_performance_department_id
      );
      if (shiftsResult?.ok) {
        setShiftData(
          Object.entries(shiftsResult.data.performanceByShifts || {}).map(([shifts, data]) => ({
            shifts: parseInt(shifts, 10),
            value: data.average_performance || 0,
            count: data.workers?.length || 0
          }))
        );
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.ERROR,
          title: shiftsResult.error
        });
      }
    } catch (err) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.ERROR
      });
    }
  };

  // Fetch and set starter performance data
  const fetchStarterData = async () => {
    try {
      const starterResult = await getNewStarterPerformance(
        getClientId(),
        selected_performance_agency_id,
        getRegionId(),
        getSiteId(),
        selected_performance_shift_id,
        selected_performance_department_id,
        startDate,
        endDate
      );
      if (starterResult?.ok) {
        setStarterData({
          performance: starterResult.data.averagePerformance || [],
          count: starterResult.data.averageShiftCounts || []
        });
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.ERROR,
          title: starterResult.error
        });
      }
    } catch (err) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.ERROR
      });
      // toast.error(err.message || 'An error occurred while fetching starter data');
    }
  };

  // Fetch and set tenure performance data
  const fetchTenureData = async () => {
    try {
      const tenureResult = await getPerformanceByTenure(
        getClientId(),
        selected_performance_agency_id,
        getRegionId(),
        getSiteId(),
        selected_performance_shift_id,
        selected_performance_department_id,
        startDate,
        endDate
      );
      if (tenureResult?.ok) {
        setTenureData({
          performance: tenureResult.data.averagePerformance || [],
          count: tenureResult.data.averageShiftCounts || []
        });
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.ERROR,
          title: tenureResult.error
        });
      }
    } catch (err) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.ERROR
      });
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    await Promise.all([fetchShiftPerformanceData(), fetchStarterData(), fetchTenureData()]);

    setIsLoading(false);
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      fetchData();
    }, 1000);
  }, [
    selected_performance_agency_id,
    selected_performance_region_id,
    selected_performance_site_id,
    selected_performance_shift_id,
    selected_performance_department_id,
    startDate,
    endDate
  ]);

  const getMaxValue = (data) => {
    if (!data || !Array.isArray(data)) return 100;
    const maxVal = Math.max(...data.filter((val) => !Number.isNaN(val)));
    return maxVal <= 0 ? 100 : Math.ceil(maxVal / 50) * 50;
  };

  const starterOptions = {
    chart: {
      type: 'line',
      toolbar: { show: false },
      height: 350,
      zoom: {
        enabled: false,
        allowMouseWheelZoom: false
      }
    },
    colors: ['#fad6ca', '#E53E3E'],
    stroke: { width: [0, 3], curve: 'smooth' },
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      },
      active: {
        filter: {
          type: 'none'
        }
      }
    },
    fill: {
      opacity: [1, 1],
      type: ['solid', 'solid'],
      colors: ['#fad6ca', '#E53E3E']
    },
    labels: [
      'Week 1',
      'Week 2',
      'Week 3',
      'Week 4',
      'Week 5',
      'Week 6',
      'Week 7',
      'Week 8',
      'Week 9',
      'Week 10',
      'Week 11',
      'Week 12'
    ],
    grid: {
      borderColor: '#E2E8F0',
      row: { colors: ['transparent'], opacity: 0.5 },
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } }
    },
    markers: {
      size: 5,
      hover: {
        size: 7
      }
    },
    xaxis: {
      type: 'category',
      labels: {
        rotate: -45,
        style: {
          fontSize: '12px'
        }
      }
    },
    yaxis: [
      {
        title: {
          text: 'Average Shift Count'
        },
        min: 0,
        labels: {
          formatter: (val) => val.toFixed(2)
        }
      },
      {
        opposite: true,
        title: {
          text: 'Performance'
        },
        min: 0,
        max: getMaxValue(starterData?.performance),
        tickAmount: 5,
        labels: {
          formatter: (val) => val.toFixed(2)
        }
      }
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: [
        {
          formatter: (y) => `${y.toFixed(2)}`
        },
        {
          formatter: (y) => y.toFixed(2)
        }
      ]
    }
  };

  const tenureOptions = {
    chart: {
      type: 'line',
      toolbar: { show: false },
      height: 350,
      zoom: {
        enabled: false,
        allowMouseWheelZoom: false
      }
    },
    colors: ['#4b80c4', '#E53E3E'],
    stroke: { width: [0, 3], curve: 'smooth' },
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      },
      active: {
        filter: {
          type: 'none'
        }
      }
    },
    fill: {
      opacity: [1, 1],
      type: ['solid', 'solid'],
      colors: ['#4b80c4', '#E53E3E']
    },
    grid: starterOptions.grid,
    markers: starterOptions.markers,
    tooltip: starterOptions.tooltip,
    labels: ['Below 1 Year', '1 Year', '2 Years', '3 Years', '4 Years', '5 Years'],
    xaxis: {
      type: 'category',
      labels: {
        rotate: 0,
        style: {
          fontSize: '12px'
        }
      }
    },
    yaxis: [
      starterOptions.yaxis[0],
      {
        ...starterOptions.yaxis[1],
        max: getMaxValue(tenureData?.performance)
      }
    ]
  };

  const starterSeries = [
    {
      name: 'Average Shift Count',
      type: 'column',
      data: starterData?.count?.map((val) => Number(val)) || []
    },
    {
      name: 'Performance',
      type: 'line',
      data: starterData?.performance?.map((val) => Number(val)) || []
    }
  ];

  const tenureSeries = [
    {
      name: 'Average Shift Count',
      type: 'column',
      data: tenureData?.count?.map((val) => Number(parseFloat(val).toFixed(2))) || []
    },
    {
      name: 'Performance',
      type: 'line',
      data: tenureData?.performance?.map((val) => Number(parseFloat(val).toFixed(2))) || []
    }
  ];

  // Function to download CSV
  const downloadCSV = () => {
    const siteName = () => {
      switch (user_details.user_type_id) {
        case USER_TYPE.AGENCY_ADMIN:
          return selected_performance_site_id ? selected_performance_site_name : '';
        case USER_TYPE.AGENCY_SITE_ADMIN:
          return selected_site_name;
        case USER_TYPE.AGENCY_REGION_ADMIN:
          return selected_performance_site_id ? selected_performance_site_name : '';
        case USER_TYPE.CLIENT_ADMIN:
          return selected_performance_site_id ? selected_performance_site_name : '';
        case USER_TYPE.SITE_ADMIN:
          return user_details.name;
        default:
          return selected_performance_site_name || selected_site_name;
      }
    };
    const regionName = () => {
      switch (user_details.user_type_id) {
        case USER_TYPE.AGENCY_ADMIN:
          return selected_performance_region_id ? selected_performance_region_name : '';
        case USER_TYPE.AGENCY_REGION_ADMIN:
          return user_details.name;
        case USER_TYPE.CLIENT_ADMIN:
          return selected_performance_region_id ? selected_performance_region_name : '';
        case USER_TYPE.REGION_ADMIN:
          return user_details.name;
        default:
          return ''; // Return empty for SITE_ADMIN and AGENCY_SITE_ADMIN
      }
    };
    const headers = [
      'start_date',
      'end_date',
      ...(user_details.user_type_id === USER_TYPE.SITE_ADMIN ||
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
        ? []
        : ['region']), // Conditionally add 'region' header
      'site',
      'shift',
      'department',
      'shift_1',
      'shift_2',
      'shift_3',
      'shift_4',
      'shift_5',
      'shift_6',
      'shift_7',
      'avg_shift_wk1',
      'avg_shift_wk2',
      'avg_shift_wk3',
      'avg_shift_wk4',
      'avg_shift_wk5',
      'avg_shift_wk6',
      'avg_shift_wk7',
      'avg_shift_wk8',
      'avg_shift_wk9',
      'avg_shift_wk10',
      'avg_shift_wk11',
      'avg_shift_wk12',
      'avg_performance_wk1',
      'avg_performance_wk2',
      'avg_performance_wk3',
      'avg_performance_wk4',
      'avg_performance_wk5',
      'avg_performance_wk6',
      'avg_performance_wk7',
      'avg_performance_wk8',
      'avg_performance_wk9',
      'avg_performance_wk10',
      'avg_performance_wk11',
      'avg_performance_wk12',
      '6m_avg_performance',
      '1Y_avg_performance',
      '2Y_avg_performance',
      '3Y_avg_performance',
      '4Y_avg_performance',
      '5Y_avg_performance'
    ];

    const rows = []; // Prepare to collect rows of data

    const averagePerformanceValue = starterData?.performance;
    const averageShiftCount = starterData?.count;
    const row = [
      startDate,
      endDate,
      ...(user_details.user_type_id === USER_TYPE.SITE_ADMIN ||
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
        ? []
        : [regionName()]), // Conditionally add region value
      siteName(),
      selected_performance_shift_id ? selected_performance_shift_name : '',
      selected_performance_department_id ? selected_performance_department_name : '',
      ...shiftData.map((shift) => shift.value), // Assuming shift data is available
      ...averageShiftCount.map((value) => value), // Placeholder for average shift weeks
      ...averagePerformanceValue.map((value) => value), // Placeholder for average performance weeks
      tenureData?.performance[0] || 0, // 6m_avg_performance
      tenureData?.performance[1] || 0, // 1Y_avg_performance
      tenureData?.performance[2] || 0, // 2Y_avg_performance
      tenureData?.performance[3] || 0, // 3Y_avg_performance
      tenureData?.performance[4] || 0, // 4Y_avg_performance
      tenureData?.performance[5] || 0 // 5Y_avg_performance
    ];
    rows.push(row);
    // Convert to CSV format
    const csvContent = [
      headers.join(','), // Header row
      ...rows.map((e) => e.join(',')) // Data rows
    ].join('\n');

    // Create a blob and download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `new_starter_performance_${startDate}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <VStack spacing={2} align="stretch">
      <Box mb={5}>
        <Card>
          <Flex justify="flex-end">
            <Button disabled={isLoading} onClick={downloadCSV} colorScheme="blue">
              Export
            </Button>
          </Flex>
        </Card>
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Grid templateColumns="repeat(7, 1fr)">
            {shiftData.map(({ shifts, value, count }) => (
              <GridItem key={shifts} textAlign="center">
                <Box
                  borderRadius="15px"
                  bg="white"
                  boxShadow="0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.06)"
                  _hover={{
                    transform: 'translateY(-1px)',
                    boxShadow:
                      '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
                  }}
                  transition="all 0.2s"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  borderWidth="1px"
                  borderColor="gray.200"
                  p={2}
                  maxWidth="12rem">
                  <Text color="gray.600" fontSize="sm" mb={1} fontWeight="medium">
                    {shifts} {shifts === 1 ? 'Shift' : 'Shifts'}
                  </Text>
                  <Text fontSize="5xl" fontWeight="700" color="blue.800">
                    {value}
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    {count} workers
                  </Text>
                </Box>
              </GridItem>
            ))}
          </Grid>

          <StyledBox>
            <Flex justify="center">
              <Heading as="h2" size="lg" mb={2} color="blue.700">
                New Starter Performance
              </Heading>
            </Flex>
            <Box height="300px">
              <ReactApexChart
                options={starterOptions}
                series={starterSeries}
                type="line"
                height="100%"
              />
            </Box>
          </StyledBox>

          <StyledBox>
            <Flex justify="center">
              <Heading as="h2" size="lg" mb={2} color="blue.700">
                Performance by Tenure
              </Heading>
            </Flex>
            <Box height="300px">
              <ReactApexChart
                options={tenureOptions}
                series={tenureSeries}
                type="line"
                height="100%"
              />
            </Box>
          </StyledBox>
        </>
      )}
    </VStack>
  );
};

export default NewStarterPerformance;
