import React, { useEffect, useMemo } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import NewStarterPerformance from './components/NewStarterPerformance';
import ShiftPerformanceList from './components/ShiftPerformanceList';
import DashboardCard from '../../components/DashboardCard';
import SiteViewPerformance from './components/SiteViewPerformance';
import { PAGE_TITLE } from '../../constants';

const PerformanceDashboard = () => {
  const { selected_performance_dashboard_type, is_restricted } = useSelector(
    (state) => state.agency
  );

  useEffect(() => {
    document.title = PAGE_TITLE.REPORTING;
  }, []);

  const renderPerformanceContent = useMemo(() => {
    if (is_restricted) {
      return (
        <Box h="600px" display="flex" justifyContent="center">
          <Center fontSize={35}>Client Has Restricted View</Center>
        </Box>
      );
    }
    switch (selected_performance_dashboard_type) {
      case 'Performance Dashboard':
        return <NewStarterPerformance />;
      case 'Shift Report':
        return <ShiftPerformanceList />;
      case 'Site View':
        return <SiteViewPerformance />;
      default:
        return (
          <DashboardCard m="5px">
            <Text textAlign="center" color="gray.500">
              Please select a dashboard type
            </Text>
          </DashboardCard>
        );
    }
  }, [selected_performance_dashboard_type, is_restricted]);

  return <Box>{renderPerformanceContent}</Box>;
};

export default PerformanceDashboard;
