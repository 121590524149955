import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  useToast,
  VStack,
  HStack,
  IconButton,
  Image,
  Flex,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  Thead,
  Spinner,
  Text
} from '@chakra-ui/react';
import DeleteBlueIcon from '../../../../assets/images/delete-blue-icon.png';
import AddIcon from '../../../../assets/images/add.png';
import ModalComponent from '../../../../components/Modal';
import Label from '../../../../components/Label';
import AlertModalOverlay from '../../../../components/AlertModalComponent';
import FinancialRuleAddEdit from '../FinancialRuleAddEdit';
import {
  CUSTOM_TOAST_DURATION,
  CUSTOM_TOAST_DURATION_NULL,
  TOAST_SETTINGS
} from '../../../../constants';
import {
  addNewYearlyRule,
  deleteYearlyRule,
  getYearlyRule,
  updateYearlyRule
} from '../../../../redux/action/client.action';
import { filterErrorData } from '../../../../utils/helper';

// Utility function for date formatting
const formatDate = (dateStr) => {
  if (!dateStr) return '';
  const [year, month, day] = dateStr.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(
    2,
    '0'
  )}-${date.getFullYear()}`;
};

const FinancialRuleList = function ({ isOpen, onClose, clientId, clientWeekDay }) {
  const toast = useToast();
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const [openFinancialRuleModal, setOpenFinancialRuleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRuleId, setDeleteRuleId] = useState(false);
  const [yearlyRules, setYearlyRules] = useState([]);

  const fetchYearlyRules = async () => {
    try {
      setLoading(true);
      const result = await getYearlyRule(clientId);

      if (result.ok && result.data.ok) {
        setYearlyRules(result.data.rules);
      }
    } catch (error) {
      toast({
        title: 'Error fetching rules',
        status: 'error',
        duration: 3000
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    if (clientId) {
      fetchYearlyRules();
    }
  }, [clientId]);

  const handleDelete = async (ruleId) => {
    setDeleteRuleId(ruleId);
    setIsDeleteModalOpen(true);
  };
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const confirmDelete = async () => {
    try {
      if (deleteRuleId) {
        const result = await deleteYearlyRule(clientId, deleteRuleId);
        if (result.ok && result.data.ok) {
          fetchYearlyRules();
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION_NULL.duration,
            status: TOAST_SETTINGS.ERROR,
            title: filterErrorData(result.error)
          });
        }
      }
    } catch (error) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.ERROR,
        title: 'Failed to delete rule'
      });
    }
    closeDeleteModal();
  };

  const handleEdit = (index) => {
    setSelectedRule(yearlyRules[index]);

    setIsEditMode(true);
    setOpenFinancialRuleModal(true);
  };

  const handleAdd = () => {
    if (yearlyRules.length) {
      setSelectedRule(yearlyRules[yearlyRules.length - 1]);
    }
    setIsEditMode(false);
    setOpenFinancialRuleModal(true);
  };

  const handleSave = async (formData) => {
    try {
      const payload = {
        start_date: formData.weekStartDate.format('YYYY-MM-DD'),
        end_date: formData.weekEndDate.format('YYYY-MM-DD'),
        total_weeks: formData.selectedWeeks.value,
        ni_percent: formData.niPercentage,
        ni_threshold: formData.niThreshold,
        pension_percent: formData.pensionPercentage,
        pension_threshold: formData.pensionThreshold,
        app_levy_percent: formData.appLevyPercentage
      };
      let result;
      setLoading(true);

      if (isEditMode) {
        result = await updateYearlyRule(clientId, selectedRule.id, payload);
      } else {
        result = await addNewYearlyRule(clientId, payload);
      }

      if (result.ok && result.data.ok) {
        fetchYearlyRules();
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION_NULL.duration,
          status: TOAST_SETTINGS.ERROR,
          title: filterErrorData(result.error)
        });
      }

      setIsEditMode(false);
      setSelectedRule(null);
      setOpenFinancialRuleModal(false);
    } catch (error) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.ERROR,
        title: 'Something went wrong try again later'
      });
    }
    setLoading(false);
  };
  return (
    <>
      <FinancialRuleAddEdit
        isOpen={openFinancialRuleModal}
        onClose={() => {
          setSelectedRule(null);
          setOpenFinancialRuleModal(false);
        }}
        isEdit={isEditMode}
        defaultDate={selectedRule?.end_date}
        onSave={handleSave}
        clientWeekDay={clientWeekDay}
        selectedData={selectedRule}
        selectedIndex={yearlyRules.findIndex((rule) => rule.id === selectedRule?.id)}
        rulesExists={!!yearlyRules.length}
      />
      <ModalComponent
        specifySize="6xl"
        isOpen={isOpen}
        onClose={onClose}
        modalTitle="Manage Financial Rules"
        modalContent={
          <VStack spacing={4} align="stretch">
            <Flex mb="10px" justify="space-between" align="center">
              <Flex cursor="pointer" alignItems="center" onClick={handleAdd}>
                <Image mr="10px" src={AddIcon} height={6} />
                <Label label="Add Rule" color="main.primary" />
              </Flex>
            </Flex>
            {loading ? (
              <Flex justify="center" align="center" height="200px">
                <Spinner size="md" />
              </Flex>
            ) : (
              <Box overflowX="auto">
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th px={2} py={1} fontWeight="bold" color="blue.600">
                        Financial Year
                      </Th>
                      <Th px={2} py={1} fontWeight="bold" color="blue.600">
                        Start Date
                      </Th>
                      <Th px={2} py={1} fontWeight="bold" color="blue.600">
                        Total Weeks
                      </Th>
                      <Th px={2} py={1} fontWeight="bold" color="blue.600">
                        End Date
                      </Th>
                      <Th px={2} py={1} fontWeight="bold" color="blue.600">
                        Actions
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {yearlyRules.length === 0 ? (
                      <Tr>
                        <Td colSpan="6">
                          <Text align="center">
                            No Financial Rules Available. Click the + icon to add a new rule.
                          </Text>
                        </Td>
                      </Tr>
                    ) : (
                      yearlyRules.map((rule, idx) => (
                        <Tr key={rule.id}>
                          <Td px={2} py={1}>
                            {rule.finacial_year_start} - {rule.finacial_year_end}
                          </Td>
                          <Td px={2} py={1}>
                            {formatDate(rule.start_date)}
                          </Td>
                          <Td px={2} py={1}>
                            {rule.total_weeks}
                          </Td>
                          <Td px={2} py={1}>
                            {formatDate(rule.end_date)}
                          </Td>
                          <Td px={2} py={1}>
                            <HStack spacing={2}>
                              <IconButton
                                size="sm"
                                icon={<Image src={DeleteBlueIcon} height={4} />}
                                onClick={() => handleDelete(rule.id)}
                                variant="ghost"
                              />
                              <Button size="sm" onClick={() => handleEdit(idx)}>
                                Edit
                              </Button>
                            </HStack>
                          </Td>
                        </Tr>
                      ))
                    )}
                  </Tbody>
                </Table>
              </Box>
            )}
          </VStack>
        }
      />
      <AlertModalOverlay
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        title="Delete Rule"
        body={
          <>
            <Text fontSize="md" fontWeight="semibold">
              Are you sure you want to delete this rule?
            </Text>
            <Text fontSize="sm" color="red" mt={5}>
              Note: If you delete an initial or intermediate rule, the following rule will also be
              deleted.
            </Text>
          </>
        }
        negative={
          <Button
            onClick={closeDeleteModal}
            bg="main.secondary"
            mr={3}
            _hover={{ bg: 'main.secondary' }}
            color="main.black">
            Cancel
          </Button>
        }
        positive={
          <Button onClick={confirmDelete} colorScheme="blue" ml={3}>
            Delete
          </Button>
        }
      />
    </>
  );
};

export default FinancialRuleList;
