/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Box, Button, Center, Flex, Tbody } from '@chakra-ui/react';
import Dropdown from '../Dropdown';
import {
  StyledContainer,
  StyledHeadTr,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledThead,
  StyledTr
} from './ServerSideDatatable.styled';
import ArrowUp from '../../icons/ArrowUp';
import ArrowDown from '../../icons/ArrowDown';
import { colors } from '../../theme';
import { PATH, USER_TYPE } from '../../constants';

const ServerSideDatatable = function (props) {
  const {
    size,
    onPaginate,
    afterPaginateData,
    pagination,
    refresh,
    column,
    pages,
    dataKey,
    initialSortKey,
    id,
    workerType,
    sortByIdDesc,
    moveToFirstPage,
    userType
  } = props;
  const [dropDownValue, setDropdownValue] = useState(size || 10);
  const [pageNumber, setPageNumber] = useState(1);

  const [totalRecode, setTotalRecords] = useState(0);
  const [rowData, setRow] = useState([]);

  const [update, setUpdate] = useState(false);

  // sorting
  const [sortKey, setSortKey] = useState(initialSortKey);
  const [isDesc, setIsDesc] = useState(false);
  const [currPage, setCurrPage] = useState('');
  useEffect(() => {
    const url = window.location.href;
    const page = url.split('/')[url.split('/').length - 1];
    setCurrPage(page);
  }, [window.location.href]);

  const sortData = async (key) => {
    let sorting = 'ASC';
    if (sortKey === key) {
      sorting = isDesc ? 'ASC' : 'DESC';
      setIsDesc(!isDesc);
    } else {
      setIsDesc(false);
    }
    setSortKey(key);
    setPageNumber(1);
    const result = await onPaginate(dropDownValue, 1, key, sorting, id || undefined);
    // console.log("result ::::::",result)
    afterPaginateData();
    setRow(pagination ? result.data[dataKey] : result);
    setTotalRecords(pagination ? result.data.count : result.length);
  };

  useEffect(() => {
    if (sortByIdDesc) {
      setIsDesc(true);
    }
    (async () => {
      const result = await onPaginate(
        dropDownValue,
        pageNumber,
        sortKey,
        isDesc ? 'DESC' : 'ASC',
        id || undefined
      );
      afterPaginateData();
      setTotalRecords(pagination ? result.data.count : result.length);
      setRow(pagination ? result.data[dataKey] : result);
      setUpdate(true);
    })();
  }, []);

  useEffect(() => {
    if (!moveToFirstPage) {
      (async () => {
        if (update) {
          const result = await onPaginate(
            dropDownValue,
            pageNumber,
            sortKey,
            isDesc ? 'DESC' : 'ASC',
            id || undefined
          );
          setTotalRecords(pagination ? result.data.count : result.length);
          setRow(pagination ? result.data[dataKey] : result);
          afterPaginateData();
        }
      })();
    } else {
      (async () => {
        if (update) {
          const result = await onPaginate(
            dropDownValue,
            1,
            sortKey,
            isDesc ? 'DESC' : 'ASC',
            id || undefined
          );
          setTotalRecords(pagination ? result.data.count : result.length);
          setRow(pagination ? result.data[dataKey] : result);
          setPageNumber(1);
          afterPaginateData();
        }
      })();
    }
  }, [refresh, moveToFirstPage]);

  // const setTablePageSize = async (page) => {
  //   setPageSize(page);
  //   const result = await onPaginate(page, pageNumber, sortKey, isDesc ? 'DESC' : "ASC");
  //   setRow(pagination ? result.data[dataKey] : result);
  //   setTotalRecords(pagination ? result.data.count : result.length);
  //   // alert(page);
  // };
  const previousButtonClick = async (pageNumber) => {
    setPageNumber(pageNumber);
    const result = await onPaginate(
      dropDownValue,
      pageNumber,
      sortKey,
      isDesc ? 'DESC' : 'ASC',
      id || undefined
    );
    afterPaginateData();
    setRow(pagination ? result.data[dataKey] : result);
    setTotalRecords(pagination ? result.data.count : result.length);
  };
  const nextButtonClick = async (pageNumber) => {
    setPageNumber(pageNumber + 1);
    const result = await onPaginate(
      dropDownValue,
      pageNumber + 1,
      sortKey,
      isDesc ? 'DESC' : 'ASC',
      id || undefined
    );
    afterPaginateData();
    setRow(pagination ? result.data[dataKey] : result);
    setTotalRecords(pagination ? result.data.count : result.length);
  };
  // const pageNumberClick = async (pageNumber) => {
  //   setPageNumber(pageNumber);
  //   const result = await onPaginate(dropDownValue, pageNumber + 1, sortKey, isDesc ? 'DESC' : "ASC");
  //   setRow(pagination ? result.data[dataKey] : result);
  //   setTotalRecords(pagination ? result.data.count : result.length);
  // };

  // const PageNumber = () => {
  //   const list = [];
  //   for (let i = 1; i <= Math.ceil(totalRecode / dropDownValue); i++) {
  //     list.push(
  //       <Button key={i} onClick={() => pageNumberClick(i)} active={pageNumber === i}>
  //         {i}
  //       </Button>
  //     );
  //   }
  //   return list;
  // };

  useEffect(() => {
    if (`/${currPage}` === PATH.WORKER.TO) {
      setPageNumber(1);
      setSortKey('first_name');
      setIsDesc(false);
    }
  }, [workerType]);

  const changePage = async (value) => {
    setDropdownValue(value);
    const result = await onPaginate(
      value,
      pageNumber,
      sortKey,
      isDesc ? 'DESC' : 'ASC',
      id || undefined
    );
    afterPaginateData();
    setRow(pagination ? result.data[dataKey] : result);
    setTotalRecords(pagination ? result.data.count : result.length);
  };

  function checkTempWork(field, data) {
    const allowedFields = ['nationality', 'national_insurance_number', 'date_of_birth'];

    if (
      workerType === 'TEMPORARY' &&
      allowedFields.includes(field) &&
      [
        USER_TYPE.CLIENT_ADMIN,
        USER_TYPE.SITE_ADMIN,
        USER_TYPE.MESSAGE_ADMIN,
        USER_TYPE.REGION_ADMIN
      ].includes(userType)
    ) {
      return '-';
    }

    return data;
  }

  return (
    <StyledContainer>
      <Box width="100%" overflowX="auto">
        <StyledTable variant="simple" style={{ backgroundColor: 'white' }}>
          <StyledThead>
            <StyledHeadTr>
              {column.map((header, index) => {
                return header.sort ? (
                  <StyledTh
                    className={sortKey === header.field ? 'active' : ''}
                    key={`th-${index}`}
                    onClick={() => sortData(header.field)}>
                    {header.label}
                    {sortKey === header.field ? (
                      isDesc ? (
                        <ArrowDown
                          style={{
                            display: 'inline-block',
                            color: colors.main.semiPrimary,
                            marginLeft: '3px'
                          }}
                        />
                      ) : (
                        <ArrowUp
                          style={{
                            display: 'inline-block',
                            color: colors.main.semiPrimary,
                            marginLeft: '3px'
                          }}
                        />
                      )
                    ) : (
                      <ArrowUp
                        style={{
                          display: 'inline-block',
                          color: colors.main.semiPrimary,
                          marginLeft: '3px'
                        }}
                      />
                    )}
                  </StyledTh>
                ) : (
                  <StyledTh key={`th-${index}`}>{header.label}</StyledTh>
                );
              })}
            </StyledHeadTr>
          </StyledThead>
          <Tbody>
            {rowData && rowData.length > 0 ? (
              rowData.map((data, trIndex) => {
                return (
                  <StyledTr key={trIndex}>
                    {column.map((d, tdIndex) => {
                      if (!d.hide) {
                        return (
                          <StyledTd key={tdIndex} length={column.length} columnname={d?.field}>
                            <span>
                              {d.autoincrement
                                ? (pageNumber - 1) * dropDownValue + trIndex + 1
                                : d.customRow
                                ? d.field === 'date_of_birth'
                                  ? checkTempWork(d.field, data[d.field])
                                  : d.customRow(data[d.field], data)
                                : data[d.field] === '' ||
                                  data[d.field] === null ||
                                  data[d.field] === undefined
                                ? '-'
                                : checkTempWork(d.field, data[d.field])}
                            </span>
                          </StyledTd>
                        );
                      }
                    })}
                  </StyledTr>
                );
              })
            ) : (
              <StyledTr>
                <StyledTd colSpan={column.length}>
                  <Center>No Data Found!</Center>
                </StyledTd>
              </StyledTr>
            )}
          </Tbody>
        </StyledTable>
      </Box>
      {pagination && rowData && rowData.length > 0 ? (
        <Flex justifyContent="flex-end" p="10px 0">
          {false && (
            <Box mr="10px">
              <Dropdown
                label=""
                onSelect={(e) => {
                  changePage(e.value);
                }}
                options={pages.map((page) => {
                  return { label: page, value: page };
                })}
                value={{ label: dropDownValue, value: dropDownValue }}
              />
            </Box>
          )}
          <>
            <Box mr="10px" p="10px 0">
              Showing {(pageNumber - 1) * dropDownValue + 1} to{' '}
              {pageNumber * dropDownValue < totalRecode ? pageNumber * dropDownValue : totalRecode}{' '}
              of {totalRecode} entries
            </Box>
            <Box>
              <Button
                onClick={() => previousButtonClick(pageNumber - 1)}
                disabled={pageNumber <= 1}
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                mr="1px"
                borderRadius="10px 0 0 10px">
                {' < '}
              </Button>
              {/* {<PageNumber />} */}
              <Button
                onClick={() => nextButtonClick(pageNumber)}
                disabled={!(pageNumber < Math.ceil(totalRecode / dropDownValue))}
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                borderRadius="0 10px 10px 0">
                {' > '}
                {/* <PaginationLink onClick={() => nextButtonClick(pageNumber)} next /> */}
              </Button>
            </Box>
          </>
        </Flex>
      ) : (
        ''
      )}
    </StyledContainer>
  );
};

export default ServerSideDatatable;
